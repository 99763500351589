import { useField, notEmpty } from '@shopify/react-form'
import { useTranslation } from 'react-i18next'

import { triggerConditionValidator } from '../validators/triggerCondition'
import {
  CAMPAIGN_CONDITION_TYPE,
  CAMPAIGN_STATUSES,
  CAMPAIGN_TRIGGER_OPTIONS,
} from '../../../../../../../../constants/checkoutUpsell'

export default function useFormFields(currentValues) {
  const { t } = useTranslation()
  const schema = getFields(currentValues, t)

  return schema.reduce((acc, field) => {
    if (field.associatedFieldHook === 'useField') {
      acc[field.name] = useField({
        value: field.value,
        validates: field.validates,
      })
    }
    return acc
  }, {})
}

const getFields = (currentValues, t) => [
  {
    name: 'name',
    value: currentValues?.name || '',
    associatedFieldHook: 'useField',
    validates: [
      notEmpty(t('CheckoutUpsell.CampaignCreate.section.form.name.emptyText')),
      (value) =>
        value.length < 3 &&
        t('CheckoutUpsell.CampaignCreate.section.form.name.minLength'),
      (value) =>
        value.length < 50 &&
        t('CheckoutUpsell.CampaignCreate.section.form.name.maxLength'),
    ],
  },
  {
    name: 'duration',
    value: {
      start: currentValues.startDate
        ? new Date(currentValues.startDate * 1000)
        : null,
      end: currentValues.endDate
        ? new Date(currentValues.endDate * 1000)
        : null,
    },
    associatedFieldHook: 'useField',
    validates: [],
  },
  {
    name: 'triggerType',
    value: currentValues?.triggerType || CAMPAIGN_TRIGGER_OPTIONS.ALL,
    associatedFieldHook: 'useField',
    validates: [],
  },
  {
    name: 'triggerConditionType',
    value: currentValues?.triggerConditionType || CAMPAIGN_CONDITION_TYPE.OR,
    associatedFieldHook: 'useField',
    validates: [],
  },
  {
    name: 'triggerConditions',
    value: currentValues?.triggerConditions || [],
    associatedFieldHook: 'useField',
    validates: [triggerConditionValidator(t)],
  },
  {
    name: 'products',
    value: currentValues?.products || [],
    associatedFieldHook: 'useField',
    validates: [],
  },
  {
    name: 'status',
    value: currentValues?.status || CAMPAIGN_STATUSES.ACTIVE,
    associatedFieldHook: 'useField',
    validates: [],
  },
]
