import PERMISSIONS from "../constants/access"

export function checkForUserPermission(data,scopes){
    const userScopes = data.user.associatedUserScope
    return scopes.every(scope => userScopes.includes(scope))
}

export function canAccessResourcePicker(data){
    const scopes = [
        PERMISSIONS.READ_PRODUCT_LISTINGS,
        PERMISSIONS.WRITE_PRODUCTS
    ]

    return checkForUserPermission(data,scopes)
}