import {
  BlockStack,
  Button,
  Card,
  DatePicker,
  InlineStack,
  Popover,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
  Tooltip,
} from '@shopify/polaris'
import React, { useCallback, useRef, useState } from 'react'
import {
  currencyFormatter,
  numberFormater,
} from '../../../../../utils/formater'
import { CalendarTimeIcon } from '@shopify/polaris-icons'
import { format } from 'date-fns'

export default function MetricsCards({
  metrics,
  loading,
  durationFilter,
  onDurationFilterChange,
}) {
  const ref = useRef()
  return (
    <Card padding={'0'}>
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          borderRadius: 'var(--p-border-radius-400)',
        }}
      >
        <div
          ref={ref}
          style={{
            borderRight: '1px solid #e5e5e5',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DateTimeRangePopover
            durationFilter={durationFilter}
            handleDurationFilterChange={onDurationFilterChange}
          />
        </div>
        {loading
          ? Array.from({
              length: 3,
            }).map((_, indx) => (
              <div
                key={indx}
                style={{
                  padding: 'var(--p-space-400)',
                }}
              >
                <BlockStack gap="100">
                  <SkeletonDisplayText size="medium" />
                  <SkeletonBodyText lines={1} />
                </BlockStack>
              </div>
            ))
          : metrics.map((metric, indx) => (
              <div key={indx}>
                <MetricCard {...metric} isLast={indx + 1 == metrics.length} />
              </div>
            ))}
      </div>
    </Card>
  )
}

function MetricCard({
  title,
  value,
  total,
  description,
  type,
  isLast,
  nativeValue,
  nativeTotal,
}) {
  let val = value
  if (type === 'currency') {
    val = currencyFormatter(
      window.shopify.data.shop.currency,
      typeof nativeValue === 'string' ? parseInt(nativeValue) : nativeValue,
    )
    total = currencyFormatter(
      window.shopify.data.shop.currency,
      typeof nativeTotal === 'string' ? parseInt(nativeTotal) : nativeTotal,
    )
  } else if (type === 'percentage') {
    val = `${(value * 100).toFixed(2)}%`
  } else {
    val =
      (typeof value === 'string'
        ? numberFormater(parseInt(value))
        : numberFormater(value)) || 0
    total = numberFormater(parseInt(total)) || 0
  }

  return (
    <Tooltip
      borderRadius="100"
      content={<Text variant="bodyXs">{description}</Text>}
    >
      <div
        style={{
          padding: 'var(--p-space-400)',
          borderRight: isLast ? null : '1px solid #e5e5e5',
        }}
      >
        <BlockStack gap={'200'}>
          <Text as="h4" variant="bodyLg">
            <span
              style={{
                textDecoration: 'underline',
                textDecorationStyle: 'dashed',
                textDecorationColor: 'var(--p-color-bg-fill-selected)',
                textDecorationThickness: '2px',
              }}
            >
              {title}
            </span>
          </Text>
          <InlineStack gap={'200'}>
            <Text variant="headingMd" as="strong">
              {val ?? 0}
            </Text>
            {typeof total !== 'undefined' && (
              <Text variant="subdued" as="span">
                / {total}
              </Text>
            )}
          </InlineStack>
        </BlockStack>
      </div>
    </Tooltip>
  )
}

function DateTimeRangePopover({
  loading = false,
  handleDurationFilterChange,
  durationFilter,
}) {
  const [active, setActive] = useState(false)
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    [],
  )

  return (
    <Popover
      active={active}
      activator={
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: 'var(--p-space-150)',
          }}
        >
          <Button
            loading={loading}
            onClick={() => {
              setActive(true)
            }}
            icon={CalendarTimeIcon}
            variant="monochromePlain"
          >
            {format(durationFilter?.start, 'dd MMM') +
              ' - ' +
              format(durationFilter?.end, 'dd MMM')}
          </Button>
        </div>
      }
      autofocusTarget="first-node"
      onClose={() => {
        setActive(false)
      }}
    >
      <div style={{ overflow: 'visible', padding: 'var(--p-space-150)' }}>
        <DatePicker
          allowRange
          multiMonth
          selected={durationFilter}
          onChange={handleDurationFilterChange}
          month={month}
          year={year}
          onMonthChange={handleMonthChange}
          disableDatesAfter={new Date()}
        />
      </div>
    </Popover>
  )
}
