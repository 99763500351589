import {
  Avatar,
  Badge,
  BlockStack,
  Box,
  Divider,
  Icon,
  InlineGrid,
  InlineStack,
  ResourceItem,
  ResourceList,
  Tag,
  Text,
  TextField,
} from '@shopify/polaris'
import {
  DeleteIcon,
  SearchIcon,
  PlusIcon,
  ProductIcon,
  CheckIcon,
} from '@shopify/polaris-icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { canAccessResourcePicker } from '../../../../../utils/access'
import Button from '../../../../../components/Button'

export default function ProductSelect({
  products,
  onChange,
  searchQuery,
  setSearchQuery,
  setIsResourcePickerOpen,
  canUpdate,
  form,
}) {
  const DEFAULT_PAGE_SIZE = 5
  const { t } = useTranslation()
  const [selectedItems, setSelectedItems] = useState([])
  const [page, setPage] = useState(1)

  const productsToShow = products.slice(
    (page - 1) * DEFAULT_PAGE_SIZE,
    page * DEFAULT_PAGE_SIZE,
  )

  const canPickProducts = canAccessResourcePicker(window.shopify.data)

  return (
    <>
      <Box padding={'400'}>
        <InlineGrid
          gap={'300'}
          columns={{
            sm: '1fr',
            md: '1fr',
            lg: canUpdate ? '1fr auto' : '1fr',
            xl: canUpdate ? '1fr auto' : '1fr',
          }}
        >
          <TextField
            value={searchQuery}
            onChange={setSearchQuery}
            prefix={<Icon source={SearchIcon} tone="base" />}
            placeholder={t(
              'BundlesUpsert.ProductsSelector.selector.search.placeholder',
            )}
          />
          {canUpdate && (
            <Button
              disabled={!canPickProducts}
              tooltipText={!canPickProducts && t('noProductPermission')}
              icon={PlusIcon}
              onClick={() => {
                setIsResourcePickerOpen(true)
              }}
            >
              {t('BundlesUpsert.ProductsSelector.selector.addProduct')}
            </Button>
          )}
        </InlineGrid>
      </Box>
      <Divider />
      <ResourceList
        resourceName={{
          singular: t('BundlesUpsert.ProductsSelector.selector.product'),
          plural: t('BundlesUpsert.ProductsSelector.selector.products'),
        }}
        items={productsToShow}
        promotedBulkActions={
          canUpdate
            ? [
                {
                  icon: DeleteIcon,
                  destructive: true,
                  onAction: () => {
                    const filteredProducts = products.filter(
                      (product) => !selectedItems.includes(product.id),
                    )
                    onChange(filteredProducts)
                    setSelectedItems([])
                  },
                  content: t(
                    'BundlesUpsert.ProductsSelector.selector.removeSelected',
                  ),
                },
              ]
            : undefined
        }
        selectedItems={canUpdate ? selectedItems : undefined}
        onSelectionChange={setSelectedItems}
        pagination={{
          hasNext: products.length > page * DEFAULT_PAGE_SIZE,
          onNext: () => {
            setPage(page + 1)
          },
          hasPrevious: page > 1,
          onPrevious: () => {
            setPage(page - 1)
          },
        }}
        renderItem={getProductListItem(form)}
        selectable={canUpdate}
        emptyState={
          <Box padding={'500'}>
            <InlineStack align="center">
              <Text as="strong">
                {t('BundlesUpsert.ProductsSelector.selector.emptyState')}
              </Text>
            </InlineStack>
          </Box>
        }
      />
    </>
  )
}

const getProductListItem = (form) => (item) => {
  const { id, title, images, vendor, variants, options } = item
  const media = (
    <Avatar source={images?.[0]?.originalSrc ?? ProductIcon} size="lg" />
  )

  const productOption = form.fields.options.value.find(
    (option) =>
      option.product == parseInt(id.replace('gid://shopify/Product/', '')),
  ) ?? {
    options: [],
  }

  return (
    <ResourceItem id={id} accessibilityLabel={`View details for ${title}`}>
      <BlockStack gap={'200'}>
        <InlineGrid columns={'auto 1fr'} gap={'300'}>
          {media}
          <BlockStack gap={'50'}>
            <Text fontWeight="bold" as="span">
              {title}
            </Text>
            <Text as="span">{vendor} vendor</Text>
          </BlockStack>
        </InlineGrid>
        <BlockStack gap={'100'}>
          {options.map((option) => (
            <BlockStack gap={'100'}>
              <Text as="strong">{option.name}</Text>
              <InlineStack gap={'300'}>
                {option.values.map((value, index) => {
                  const isPresent = productOption.options.find(
                    (o) => o.name === option.name && o.values.includes(value),
                  )

                  if (isPresent) {
                    return (
                      <SelectedTag
                        onClick={() => {
                          const copy = [...productOption.options]
                          const index = copy.findIndex(
                            (o) => o.name === option.name,
                          )
                          copy[index].values = copy[index].values.filter(
                            (v) => v !== value,
                          )
                          productOption.options = copy
                          const productOptionIndex =
                            form.fields.options.value.findIndex(
                              (option) =>
                                option.product ==
                                parseInt(
                                  id.replace('gid://shopify/Product/', ''),
                                ),
                            )
                          const copyOptions = [...form.fields.options.value]
                          copyOptions[productOptionIndex] = productOption
                          form.fields.options.onChange(copyOptions)
                        }}
                      >
                        {value}
                      </SelectedTag>
                    )
                  } else {
                    return (
                      <Tag
                        size="large"
                        onClick={() => {
                          const copy = [...productOption.options]
                          const index = copy.findIndex(
                            (o) => o.name === option.name,
                          )
                          copy[index].values.push(value)
                          productOption.options = copy
                          const productOptionIndex =
                            form.fields.options.value.findIndex(
                              (option) =>
                                option.product ==
                                parseInt(
                                  id.replace('gid://shopify/Product/', ''),
                                ),
                            )
                          const copyOptions = [...form.fields.options.value]
                          copyOptions[productOptionIndex] = productOption
                          form.fields.options.onChange(copyOptions)
                        }}
                        key={index}
                      >
                        {value}
                      </Tag>
                    )
                  }
                })}
              </InlineStack>
            </BlockStack>
          ))}
        </BlockStack>
      </BlockStack>
    </ResourceItem>
  )
}

function SelectedTag({ children, onClick }) {
  return (
    <button
      onClick={onClick}
      style={{
        backgroundColor: 'var(--p-color-bg-inverse)',
      }}
      type="button"
      class="Polaris-Tag Polaris-Tag--clickable Polaris-Tag--sizeLarge"
    >
      <span class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--block Polaris-Text--truncate">
        <span
          style={{
            color: 'var(--p-color-bg-surface)',
          }}
          title="30 x 30"
          class="Polaris-Tag__Text"
        >
          <InlineGrid columns={'auto 1fr'}>
            <svg
              viewBox="0 0 20 20"
              class="Polaris-Icon__Svg"
              focusable="false"
              aria-hidden="true"
              style={{
                width: '20px',
                height: '20px',
              }}
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M15.78 5.97a.75.75 0 0 1 0 1.06l-6.5 6.5a.75.75 0 0 1-1.06 0l-3.25-3.25a.75.75 0 1 1 1.06-1.06l2.72 2.72 5.97-5.97a.75.75 0 0 1 1.06 0Z"
              ></path>
            </svg>
            {children}
          </InlineGrid>
        </span>
      </span>
    </button>
  )
}
