const PERMISSIONS = {
    READ_ALL_ORDERS: "read_all_orders",
    READ_CHECKOUTS: "read_checkouts",
    READ_CUSTOMER_EVENTS: "read_customer_events",
    READ_CUSTOMERS: "read_customers",
    READ_INVENTORY: "read_inventory",
    READ_LOCALES: "read_locales",
    READ_MARKETS: "read_markets",
    READ_ORDERS: "read_orders",
    READ_PRODUCT_LISTINGS: "read_product_listings",
    READ_THEMES: "read_themes",
    UNAUTHENTICATED_READ_CONTENT: "unauthenticated_read_content",
    UNAUTHENTICATED_READ_CUSTOMER_TAGS: "unauthenticated_read_customer_tags",
    UNAUTHENTICATED_READ_METAOBJECTS: "unauthenticated_read_metaobjects",
    UNAUTHENTICATED_READ_PRODUCT_INVENTORY: "unauthenticated_read_product_inventory",
    UNAUTHENTICATED_READ_PRODUCT_LISTINGS: "unauthenticated_read_product_listings",
    UNAUTHENTICATED_READ_PRODUCT_TAGS: "unauthenticated_read_product_tags",
    UNAUTHENTICATED_READ_SELLING_PLANS: "unauthenticated_read_selling_plans",
    UNAUTHENTICATED_WRITE_CHECKOUTS: "unauthenticated_write_checkouts",
    UNAUTHENTICATED_WRITE_CUSTOMERS: "unauthenticated_write_customers",
    WRITE_DISCOUNTS: "write_discounts",
    WRITE_PIXELS: "write_pixels",
    WRITE_PRICE_RULES: "write_price_rules",
    WRITE_PRODUCTS: "write_products",
    WRITE_PUBLICATIONS: "write_publications",
    WRITE_SCRIPT_TAGS: "write_script_tags",
    WRITE_TRANSLATIONS: "write_translations"
};

export default PERMISSIONS;