import {
  InlineStack,
  Select,
  Tooltip,
  Text,
  Icon,
  Form,
  TextField,
  Grid,
  Button,
  Box,
} from '@shopify/polaris'
import { InfoIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'
import humps from 'humps'
import { useField } from '@shopify/react-form'

import useMultiLangTranlationForm from './hooks/useMultiLangTranlationForm'
import {
  TEMPLATE_LAYOUTS,
  TEMPLATE_TYPES,
} from '../../../../constants/templates'
import { updateTemplate } from '../../../../apis/template'
import queryClient from '../../../../utils/query'
import LANGUAGES from '../../../../constants/languages'

function LabelComponent({ label, helpText }) {
  return (
    <div>
      <InlineStack>
        <Text>{label}</Text>
        <Tooltip content={helpText} dismissOnMouseOut>
          <Icon source={InfoIcon} />
        </Tooltip>
      </InlineStack>
    </div>
  )
}

function MultiLanguageTranslations({ config }) {
  const { t } = useTranslation()
  const shopPrimaryLocale = window.shopify.config.locale.split('-')[0]
  const chooseLanguageField = useField(shopPrimaryLocale)
  const { fields, submit, submitting } = useMultiLangTranlationForm({
    currentValues: humps.camelizeKeys({
      ...(config.translations[
        chooseLanguageField.value == shopPrimaryLocale
          ? 'primary_locale'
          : chooseLanguageField.value
      ] ?? {}),
    }),
    chooseLanguageField,
    onSubmit: async (data) => {
      let objects = {}
      if (config.type !== TEMPLATE_TYPES.BUNDLE_SECTION) {
        objects = {
          go_to_cart_text: data.goToCartTextfield,
          add_to_cart_text: data.addToCartTextfield,
          out_of_stock_text: data.outOfStockTextfield,
          added_to_cart_text: data.addedToCartTextfield,
          adding_to_cart_text: data.addingToCartTextfield,
          discount_label_text: data.discountLabelTextfield,
          discount_text: data.discountTextTextfieled,
        }
        if (config.layout === TEMPLATE_LAYOUTS.FBT) {
          objects.fbt_add_to_cart_text = data.fbtAddToCartTextfield
          objects.this_product_text = data.thisProductTextfield
          objects.total_price_text = data.totalPriceTextfield
        }
      } else if (config.type === TEMPLATE_TYPES.BUNDLE_SECTION) {
        objects = {
          bundle_section_title: data.bundleSectionTitleTextfield,
          product_component_title: data.productComponentTitleTextField,
          total_price_text: data.totalPriceTextfield,
          add_to_cart_text: data.addToCartTextfield,
          out_of_stock_text: data.outOfStockTextfield,
          added_to_cart_text: data.addedToCartTextfield,
          bundle_discount: data.bundleDiscountTextfield,
          discount_label_text: data.discountLabelTextfield,
        }
      }
      const payload = {
        translations: {
          ...config.translations,
          [data.chooseLanguageField === shopPrimaryLocale
            ? 'primary_locale'
            : data.chooseLanguageField]: objects,
        },
      }

      const res = await updateTemplate(config.id, payload)
      if (res.error) {
        shopify.toast.show('Some Error Occured!', {
          isError: true,
        })
        return {
          status: 'error',
          message: res.error,
        }
      }

      await queryClient.invalidateQueries(['templates', config.id])
      shopify.toast.show(
        t('DefaultText.updateSuccess', {
          text: t('DefaultText.settings'),
        }),
      )
      return {
        status: 'success',
      }
    },
    type: config.layout,
    templateType: config.type,
  })

  const options = LANGUAGES.map((lang) => ({
    label: lang.label,
    value: lang.value,
  }))

  if (config.type === TEMPLATE_TYPES.BUNDLE_SECTION) {
    return (
      <div style={{ padding: 'var(--p-space-400)' }}>
        <Form onSubmit={submit}>
          <Grid gap={300}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 12, xl: 12 }}>
              <Select
                {...fields.chooseLanguageField}
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.ChooseLanguage.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.ChooseLanguage.helpText',
                    )}
                  />
                }
                options={options}
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.bundleSectionTitleTextfield}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.bundleSectionTitleTextfield.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.bundleSectionTitleTextfield.helpText',
                      {
                        count: <strong>{'{{count}}'}</strong>,
                      },
                    )}
                  />
                }
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.productComponentTitleTextField}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.productSectionTitleTextfield.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.productSectionTitleTextfield.helpText',
                      {
                        count: <strong>{'{{count}}'}</strong>,
                      },
                    )}
                  />
                }
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.totalPriceTextfield}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.totalPriceTextBundle.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.totalPriceTextBundle.helpText',
                      {
                        totalPrice: <strong>{'{{total_price}}'}</strong>,
                      },
                    )}
                  />
                }
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.addToCartTextfield}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.addToCartText.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.addToCartText.helpText',
                    )}
                  />
                }
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.addedToCartTextfield}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.addedToCartText.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.addedToCartText.helpText',
                    )}
                  />
                }
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.outOfStockTextfield}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.outOfStockText.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.outOfStockText.helpText',
                    )}
                  />
                }
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.bundleDiscountTextfield}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.bundleDiscount.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.bundleDiscount.helpText',
                    {
                        percent: <strong>{'{{percent}}'}</strong>,
                      },
                    )}
                  />
                }
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.discountLabelTextfield}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.discountLabelTextBundle.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.discountLabelTextBundle.helpText',
                      {
                        percent: <strong>{'{{percent}}'}</strong>,
                      }
                    )}
                  />
                }
              />
            </Grid.Cell>
          </Grid>
          <Box padding={'200'} />
          <Button loading={submitting} variant="primary" size="large" submit>
            {t('Templates.Configure.MultiLanguageTranslations.buttonText')}
          </Button>
        </Form>
      </div>
    )
  }

  return (
    <div style={{ padding: 'var(--p-space-400)' }}>
      <Form onSubmit={submit}>
        <Grid gap={300}>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 12, xl: 12 }}>
            <Select
              {...fields.chooseLanguageField}
              label={
                <LabelComponent
                  label={t(
                    'Templates.Configure.MultiLanguageTranslations.ChooseLanguage.label',
                  )}
                  helpText={t(
                    'Templates.Configure.MultiLanguageTranslations.ChooseLanguage.helpText',
                  )}
                />
              }
              options={options}
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <TextField
              {...fields.addToCartTextfield}
              autoComplete="text"
              type="text"
              label={
                <LabelComponent
                  label={t(
                    'Templates.Configure.MultiLanguageTranslations.addToCartText.label',
                  )}
                  helpText={t(
                    'Templates.Configure.MultiLanguageTranslations.addToCartText.helpText',
                  )}
                />
              }
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <TextField
              {...fields.addedToCartTextfield}
              autoComplete="text"
              type="text"
              label={
                <LabelComponent
                  label={t(
                    'Templates.Configure.MultiLanguageTranslations.addedToCartText.label',
                  )}
                  helpText={t(
                    'Templates.Configure.MultiLanguageTranslations.addedToCartText.helpText',
                  )}
                />
              }
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <TextField
              {...fields.addingToCartTextfield}
              autoComplete="text"
              type="text"
              label={
                <LabelComponent
                  label={t(
                    'Templates.Configure.MultiLanguageTranslations.addingToCartText.label',
                  )}
                  helpText={t(
                    'Templates.Configure.MultiLanguageTranslations.addingToCartText.helpText',
                  )}
                />
              }
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <TextField
              {...fields.discountLabelTextfield}
              autoComplete="text"
              type="text"
              label={
                <LabelComponent
                  label={t(
                    'Templates.Configure.MultiLanguageTranslations.discountLabelText.label',
                  )}
                  helpText={t(
                    'Templates.Configure.MultiLanguageTranslations.discountLabelText.helpText',
                  )}
                />
              }
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <TextField
              {...fields.goToCartTextfield}
              autoComplete="text"
              type="text"
              label={
                <LabelComponent
                  label={t(
                    'Templates.Configure.MultiLanguageTranslations.goToCartText.label',
                  )}
                  helpText={t(
                    'Templates.Configure.MultiLanguageTranslations.goToCartText.helpText',
                  )}
                />
              }
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <TextField
              {...fields.outOfStockTextfield}
              autoComplete="text"
              type="text"
              label={
                <LabelComponent
                  label={t(
                    'Templates.Configure.MultiLanguageTranslations.outOfStockText.label',
                  )}
                  helpText={t(
                    'Templates.Configure.MultiLanguageTranslations.outOfStockText.helpText',
                  )}
                />
              }
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                <TextField
                  {...fields.discountTextTextfieled}
                  autoComplete="text"
                  type="text"
                  label={
                    <LabelComponent
                      label={t(
                        'Templates.Configure.MultiLanguageTranslations.discountText.label',
                      )}
                      helpText={t(
                        'Templates.Configure.MultiLanguageTranslations.discountText.helpText',
                      )}
                    />
                  }
                />
              </Grid.Cell>
          {config.layout === TEMPLATE_LAYOUTS.FBT && (
            <>
              <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                <TextField
                  {...fields.fbtAddToCartTextfield}
                  autoComplete="text"
                  type="text"
                  label={
                    <LabelComponent
                      label={t(
                        'Templates.Configure.MultiLanguageTranslations.fbtAddToCartText.label',
                      )}
                      helpText={t(
                        'Templates.Configure.MultiLanguageTranslations.fbtAddToCartText.helpText',
                      )}
                    />
                  }
                />
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                <TextField
                  {...fields.thisProductTextfield}
                  autoComplete="text"
                  type="text"
                  label={
                    <LabelComponent
                      label={t(
                        'Templates.Configure.MultiLanguageTranslations.thisProductText.label',
                      )}
                      helpText={t(
                        'Templates.Configure.MultiLanguageTranslations.thisProductText.label',
                      )}
                    />
                  }
                />
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                <TextField
                  {...fields.totalPriceTextfield}
                  autoComplete="text"
                  type="text"
                  label={
                    <LabelComponent
                      label={t(
                        'Templates.Configure.MultiLanguageTranslations.totalPriceText.label',
                      )}
                      helpText={t(
                        'Templates.Configure.MultiLanguageTranslations.totalPriceText.helpText',
                      )}
                    />
                  }
                />
              </Grid.Cell>
            </>
          )}
        </Grid>
        <Box padding={'200'} />
        <Button loading={submitting} variant="primary" size="large" submit>
          {t('Templates.Configure.MultiLanguageTranslations.buttonText')}
        </Button>
      </Form>
    </div>
  )
}

export default MultiLanguageTranslations
