import {
  useForm,
  useField,
  useChoiceField,
  notEmpty,
} from '@shopify/react-form'
import { useTranslation } from 'react-i18next'

import { DISCOUNT_TYPES } from '../../../../constants/discounts'
import {
  BOUGHT_TOGETHER,
  CROSS_SELL,
  PROMOTED_PRODUCTS,
  RECENTLY_VIEWED,
  SIMILAR_PRODUCTS,
  WIDGET_TYPES,
} from '../../../../constants/widgets'
import { BLOG, COLLECTION, OTHER, PRODUCT } from '../../../../constants/page'
import { AMAZON_BOUGHT_TOGETHER } from '../../../../constants/layouts'
import { useEffect } from 'react'

export function useEditSection({ onSubmit, currentValues }) {
  const { t } = useTranslation()
  const collections = useField(currentValues.collections)
  const slots = useField(currentValues.slots)
  const name = useField({
    value: currentValues.name,
    validates: [notEmpty('Please enter a title')],
  })
  const experience = useField(
    {
      value: currentValues.experience,
      validates: [notEmpty('Please select an experience')],
    },
    [currentValues.experience],
  )
  const page = useField(
    {
      value: currentValues.page,
      validates: [notEmpty('Please select a page')],
    },
    [currentValues.page],
  )
  const objects = useField({
    value: currentValues.objects,
    validates: [
      (objects) => {
        const map = {}
        objects.forEach((object) => {
          if (map[object.url]) {
            map[object.url]++
          } else {
            map[object.url] = 1
          }
        })
        const isMoreThanOne = Object.values(map).some((value) => value > 1)
        return isMoreThanOne
          ? t(
              'Section.Edit.Sections.Recommendation.ObjectRecommendation.enterUniqueUrls',
            )
          : null
      },
    ],
  })
  const enabledUrls = useField({
    value: currentValues.enabledUrls,
    validates: [
      (urls) => {
        const regex =
          /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/
        const testedUrls = urls.map((url) =>
          regex.test(url)
            ? null
            : t(
                'Section.Edit.Sections.Recommendation.EnabledUrls.input.invalidUrl',
              ),
        )
        const areAllCorrect = testedUrls.reduce(
          (acc, curr) => acc && curr === null,
          true,
        )
        return areAllCorrect ? null : testedUrls
      },
    ],
  })
  const location = useField({
    value: currentValues.location,
    validates: [notEmpty('Please enter a location')],
  })
  const position = useField({
    value: currentValues.position,
    validates: [
      (val) => (val < 0 ? 'Please enter a positive number' : null),
      notEmpty('Please enter a position'),
    ],
  })
  const widgetType = useField(
    {
      value: currentValues.widgetType,
      validates: [notEmpty('Please select a widget type')],
    },
    [currentValues.widgetType],
  )
  const layout = useField(
    {
      value: currentValues.layout,
      validates: [notEmpty('Please select a layout')],
    },
    [currentValues.layout],
  )
  const template = useField(currentValues.template, [currentValues.template])
  const disabledProducts = useField(currentValues.disabledProducts)
  const excludedProducts = useField(currentValues.excludedProducts)
  const whitelistedProducts = useField(currentValues.whitelistedProducts)
  const disabledTags = useField(currentValues.disabledTags)
  const excludedTags = useField(currentValues.excludedTags)
  const whitelistedTags = useField(currentValues.whitelistedTags)

  const enableBundleDiscounts = useChoiceField(
    currentValues.enableBundleDiscounts,
  )
  const discountType = useField(
    {
      value: currentValues.discountType,
      validates: [notEmpty('Please select a discount type')],
    },
    [currentValues.discountType],
  )

  const discountValue = useField(
    {
      value: currentValues.discountValue,
      validates: [
        notEmpty('Please enter a discount value'),
        (value) => {
          const val = parseInt(value)
          //check if the value is betwenn 0 and 100 for percentage
          if (discountType.value === DISCOUNT_TYPES.PERCENTAGE.value) {
            return val < 1 || val > 100
              ? 'Please enter a value between 1 and 100'
              : null
          }
          //check if the value is between 0 and 999999 for fixed amount
          return val < 1 || val > 999999
            ? 'Please enter a value between 1 and 999999'
            : null
        },
      ],
    },
    [discountType.value, currentValues.discountValue],
  )

  const segmentation = useField(
    {
      value: currentValues.segmentation,
      validates: [notEmpty('Please select a segmentation')],
    },
    [currentValues.segmentation],
  )
  const sectionTitle = useField(
    {
      value: currentValues.sectionTitle,
      validates: [notEmpty('Please enter a section title')],
    },
    [currentValues.sectionTitle],
  )
  const showSoldOutItems = useChoiceField(currentValues.showSoldOutItems)
  const enableCartProps = useChoiceField(currentValues.enableCartProps)
  const pricePercentageThreshold = useField(
    {
      value: currentValues.pricePercentageThreshold,
      validates: [notEmpty('Please enter a price percentage threshold')],
    },
    [currentValues.pricePercentageThreshold],
  )
  const productRankingCriteria = useField(currentValues.productRankingCriteria)
  const productFilterCriteria = useField(
    {
      value: currentValues.productFilterCriteria,
      validates: [notEmpty('Please select a filter criteria')],
    },
    [currentValues.productFilterCriteria],
  )
  const fallbackCriteria = useField(currentValues.fallbackCriteria)
  const minPrice = useField(
    {
      value: currentValues.minPrice,
      validates: [notEmpty('Please enter a minimum price')],
    },
    [currentValues.minPrice],
  )
  const maxPrice = useField(
    {
      value: currentValues.maxPrice,
      validates: [notEmpty('Please enter a maximum price')],
    },
    [currentValues.maxPrice],
  )
  const automaticEnabled = useChoiceField(currentValues.automaticEnabled)
  const enableRandom = useChoiceField(currentValues.enableRandom)
  const allowIfUnavailable = useChoiceField(currentValues.allowIfUnavailable)
  const discountConfig = useField(currentValues.discountConfig)

  const translations = useField(currentValues.translations)
  const enableCartButton = useChoiceField(currentValues.enableCartButton)

  const applyDiscountOnlyToRec = useChoiceField(
    currentValues.applyDiscountOnlyToRec,
  )

  const discountMessageObject = useField({
    value: currentValues.discountMessageObject,
    validates: [
      notEmpty('Please enter a discount message'),
      (value) => {
        try {
          JSON.parse(JSON.stringify(value))
          return null
        } catch (e) {
          return t(
            t(
              'Section.Edit.Sections.DiscountConfig.form.discountMessageObject.notValid',
            ),
          )
        }
      },
    ],
  })

  const discountSubtitle = useField({
    value: currentValues.discountSubtitle,
    validates: [notEmpty('Please enter a discount subtitle')],
  })

  const discountSubtitleTranslated = useField(
    currentValues.discountSubtitleTranslated,
  )

  const discountVersion = useField(currentValues.discountVersion)

  const discountMessage = useField({
    value: currentValues.discountMessage,
    validates: [notEmpty('Please enter a discount message')],
  })

  const fields = {
    name,
    experience,
    page,
    widgetType,
    layout,
    template,
    disabledProducts,
    excludedProducts,
    disabledTags,
    excludedTags,
    segmentation,
    sectionTitle,
    showSoldOutItems,
    enableCartProps,
    productRankingCriteria,
    fallbackCriteria,
    minPrice,
    maxPrice,
    allowIfUnavailable,
    location,
    position,
    whitelistedProducts,
    whitelistedTags,
    collections,
    slots,
    objects,
    discountConfig,
    enableBundleDiscounts,
    translations,
    enableCartButton,
    discountVersion,
  }

  fields.enableBundleDiscounts = enableBundleDiscounts

  if (
    widgetType.value === BOUGHT_TOGETHER.value ||
    widgetType.value === CROSS_SELL.value ||
    widgetType.value === SIMILAR_PRODUCTS.value ||
    widgetType.value === RECENTLY_VIEWED.value
  ) {
    fields.enableRandom = enableRandom
  }

  if (
    widgetType.value === CROSS_SELL.value ||
    widgetType.value === BOUGHT_TOGETHER.value ||
    widgetType.value === SIMILAR_PRODUCTS.value ||
    widgetType.value === RECENTLY_VIEWED.value
  ) {
    fields.automaticEnabled = automaticEnabled
  }

  if (widgetType.value === BOUGHT_TOGETHER.value) {
    fields.pricePercentageThreshold = pricePercentageThreshold
  }

  if (widgetType.value === SIMILAR_PRODUCTS.value) {
    fields.productFilterCriteria = productFilterCriteria
  }

  if (
    enabledUrlsvisible({
      pageType: page.value,
      type: widgetType.value,
    })
  ) {
    fields.enabledUrls = enabledUrls
  }

  if (enableBundleDiscounts.checked) {
    fields.discountType = discountType
    fields.discountValue = discountValue
    fields.applyDiscountOnlyToRec = applyDiscountOnlyToRec
    fields.discountMessageObject = discountMessageObject
    fields.discountMessage = discountMessage
    fields.discountSubtitle = discountSubtitle
    fields.discountSubtitleTranslated = discountSubtitleTranslated
  }

  useEffect(() => {
    if (currentValues.discountSubtitle) {
      return
    }
    if (fields?.discountType?.value == DISCOUNT_TYPES.AMOUNT.value) {
      fields.discountSubtitle.onChange('Get additional {percent}% off')
    } else if (fields?.discountType?.value == DISCOUNT_TYPES.PERCENTAGE.value) {
      fields.discountSubtitle.onChange('Get {amount} off')
    }
  }, [fields?.discountType?.value])

  return useForm({
    fields: currentValues.name ? fields : {},
    onSubmit,
  })
}

const enabledUrlsvisible = (widgetData) => {
  const otherPage = widgetData.pageType === OTHER.value
  const blogPage = widgetData.pageType === BLOG.value
  const promotedProductsWidget = widgetData.type === PROMOTED_PRODUCTS.value

  return otherPage || (blogPage && !promotedProductsWidget)
}
