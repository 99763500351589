import React, { useState } from 'react'
import {
  BlockStack,
  Button,
  Card,
  InlineGrid,
  Layout,
  Text,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { EditIcon } from '@shopify/polaris-icons'

import * as PRODUCT_RANKING_CRITERIA from '../../../../constants/widgets/rankingCriteria'
import * as PRODUCT_FILTER_CRITERIA from '../../../../constants/widgets/filterCriteria'
import * as FALLBACK_CRITERIA from '../../../../constants/widgets/fallbackCriteria'

function AdvanceConfig({
  pricePercentageThreshold,
  productRankingCriteria,
  productFilterCriteria,
  fallbackCriteria,
  minimumPrice,
  maximumPrice,
  onClick,
}) {
  const { t } = useTranslation()
  return (
    <Layout.AnnotatedSection
      title={t('Section.Details.AdvanceConfig.title')}
      description={t('Section.Details.AdvanceConfig.description')}
    >
      <Card>
        <InlineGrid columns={'1fr auto'}>
          <BlockStack gap={'400'}>
            <InlineGrid columns={pricePercentageThreshold ? 2 : 1} gap={'400'}>
              {pricePercentageThreshold && (
                <div>
                  <Text as="h4" variant="bodyMd">
                    {t('Section.Details.AdvanceConfig.FormFields.PPT.label')}
                  </Text>
                  <Text variant="headingMd" as="strong">
                    {pricePercentageThreshold}
                  </Text>
                </div>
              )}
              <div>
                <Text as="h4" variant="bodyMd">
                  {t('Section.Details.AdvanceConfig.FormFields.PRC.label')}
                </Text>
                <Text variant="headingMd" as="strong">
                  {Object.values(PRODUCT_RANKING_CRITERIA).find(
                    ({ value }) => value === productRankingCriteria,
                  )?.label ?? 'Not Set'}
                </Text>
              </div>
            </InlineGrid>
            <InlineGrid columns={2} gap={'400'}>
              <div>
                <Text as="h4" variant="bodyMd">
                  {t('Section.Details.AdvanceConfig.FormFields.PFC.label')}
                </Text>
                <Text variant="headingMd" as="strong">
                  {
                    Object.values(PRODUCT_FILTER_CRITERIA).find(
                      ({ value }) => value === productFilterCriteria,
                    ).label
                  }
                </Text>
              </div>
              <div>
                <Text as="h4" variant="bodyMd">
                  {t('Section.Details.AdvanceConfig.FormFields.FC.label')}
                </Text>
                <Text variant="headingMd" as="strong">
                  {
                    Object.values(FALLBACK_CRITERIA).find(
                      ({ value }) => value === fallbackCriteria,
                    ).label
                  }
                </Text>
              </div>
            </InlineGrid>
            <InlineGrid columns={2} gap={'400'}>
              <div>
                <Text as="h4" variant="bodyMd">
                  {t('Section.Details.AdvanceConfig.FormFields.MinPrice.label')}
                </Text>
                <Text variant="headingMd" as="strong">
                  {minimumPrice}
                </Text>
              </div>
              <div>
                <Text as="h4" variant="bodyMd">
                  {t('Section.Details.AdvanceConfig.FormFields.MaxPrice.label')}
                </Text>
                <Text variant="headingMd" as="strong">
                  {maximumPrice}
                </Text>
              </div>
            </InlineGrid>
          </BlockStack>
          <div>
            <Button icon={EditIcon} onClick={onClick}>
              {t('Section.Details.BundleConfig.btnText')}
            </Button>
          </div>
        </InlineGrid>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export default AdvanceConfig
