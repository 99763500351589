/**
 * Removes specified keys from an object.
 * @param {Object} obj - The original object.
 * @param {Array} keysToRemove - The keys to remove from the object.
 * @returns {Object} - A new object with the specified keys removed.
 */
export function removeKeys(obj, keysToRemove) {
    return Object.keys(obj)
        .filter(key => !keysToRemove.includes(key))
        .reduce((newObj, key) => {
            newObj[key] = obj[key];
            return newObj;
        }, {});
}
