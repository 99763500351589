import {
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormLayout,
  InlineError,
  InlineGrid,
  Layout,
  Link,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
  TextField,
  useBreakpoints,
} from '@shopify/polaris'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

import * as LayoutTemplates from '../../../../constants/layouts'
import { getTemplates } from '../../../../apis/template'
import { PAGE_TYPES } from '../../../../constants/page'
import { BOUGHT_TOGETHER } from '../../../../constants/widgets'
import { openChat } from '../../../../components/ExternalScripts'
import { TEMPLATE_TYPES } from '../../../../constants/templates'

export default function SelectTemplate({ form, dashboardData }) {
  const { t } = useTranslation()
  const templateLayouts = Object.values(LayoutTemplates).map((layout) => ({
    ...layout,
    label: t(`Layout.${layout.value}.label`),
    description: t(`Layout.${layout.value}.supportText`),
    enabled:
      layout.value === LayoutTemplates.AMAZON_BOUGHT_TOGETHER.value
        ? form.fields.page.value === PAGE_TYPES.product_page.value &&
          form.fields.widgetType.value === BOUGHT_TOGETHER.value
        : true,
  }))

  const breakPoint = useBreakpoints()
  const templateEnabled = dashboardData.config.templatesEnabled

  const { data, isLoading } = useQuery({
    queryKey: ['templates'],
    queryFn: async () => {
      const res = await getTemplates()
      if (res.error) {
        return Promise.reject(res.error)
      }
      return res.data
    },
    refetchOnWindowFocus: false,
  })

  const templateList = (data?.templates ?? [])
    .filter((template) => template.type === TEMPLATE_TYPES.WIDGET)
    .map((template) => ({
      label: template.name,
      value: template.id,
    }))

  return (
    <>
      <Layout.AnnotatedSection
        id="template"
        variant="oneThird"
        title={t('Section.Edit.Sections.SelectTemplate.title')}
        description={t('Section.Edit.Sections.SelectTemplate.description')}
      >
        {templateEnabled ? (
          <Card padding={'0'}>
            <Box padding={'400'}>
              <InlineGrid columns={'1fr auto'} alignItems="start">
                <BlockStack gap={'200'}>
                  <Text as="strong">
                    {t(
                      'Section.Edit.Sections.SelectTemplate.visualEditorTitle',
                    )}
                  </Text>
                  <Text>
                    {t(
                      'Section.Edit.Sections.SelectTemplate.visualEditorDescription',
                    )}
                  </Text>
                </BlockStack>
                <Button
                  onClick={() => {
                    document.getElementById('visual-editor-modal')?.show()
                  }}
                >
                  {t('Section.Edit.Sections.SelectTemplate.visualEditorCTA')}
                </Button>
              </InlineGrid>
            </Box>
            <Divider />
            <Box padding={'400'}>
              <FormLayout>
                <FormLayout>
                  <InlineGrid columns={'1fr auto'} gap={'200'} alignItems="end">
                    <Select
                      label={t(
                        'Section.Edit.Sections.SelectTemplate.templateSelectHeading',
                      )}
                      options={templateList}
                      value={form.fields.template.value}
                      onChange={(val) =>
                        form.fields.template.onChange(parseInt(val))
                      }
                    />
                    <div>
                      <Button
                        url={`/settings/templates/${form.fields.template.value}/configure`}
                      >
                        {t(
                          'Section.Edit.Sections.SelectTemplate.configureTemplate',
                        )}
                      </Button>
                    </div>
                  </InlineGrid>
                </FormLayout>
                <FormLayout>
                  <BlockStack gap={'200'}>
                    <Text as="h6">
                      {t(
                        'Section.Edit.Sections.SelectTemplate.selectLayoutText',
                      )}
                    </Text>
                    {form.fields.layout?.allErrors?.length > 0 && (
                      <InlineError message={form.fields.layout.allErrors[0]} />
                    )}
                    <InlineGrid columns={'1fr 1fr'} gap={'200'}>
                      {templateLayouts
                        .filter(({ enabled }) => enabled)
                        .map((layout) => (
                          <div
                            onClick={() => {
                              if (isLoading) return
                              form.fields.layout.onChange(layout.value)
                            }}
                            style={{
                              cursor: isLoading ? 'not-allowed' : 'pointer',
                              height: '100%',
                            }}
                          >
                            <div
                              title={layout.label}
                              padding={'0'}
                              background={
                                layout.value === form.fields.layout.value
                                  ? 'bg-fill-brand-selected'
                                  : 'bg-fill'
                              }
                              style={{
                                border: '1px solid var(--p-color-border)',
                                borderRadius: 'var(--p-border-radius)',
                                backgroundColor:
                                  layout.value === form.fields.layout.value
                                    ? 'var(--p-color-bg-surface-selected)'
                                    : 'var(--p-color-bg-surface)',
                                height: '100%',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  position: 'relative',
                                }}
                              >
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: 'var(--p-space-button-group-gap)',
                                    right: 'var(--p-space-button-group-gap)',
                                    zIndex: 1000,
                                  }}
                                >
                                  <Checkbox
                                    checked={
                                      layout.value === form.fields.layout.value
                                    }
                                    onChange={(val) => {}}
                                    disabled={isLoading}
                                  />
                                </div>
                                <div
                                  style={{
                                    padding: 'var(--p-space-card-padding)',
                                    backgroundColor:
                                      'var(--p-color-bg-surface)',
                                    minHeight: '112px',
                                  }}
                                >
                                  <Text as="strong">{layout.label}</Text>
                                  <Text as="p">{layout.description}</Text>
                                </div>
                                <div
                                  style={{
                                    backgroundColor:
                                      'var(--p-color-bg-surface-selected)',
                                    flex: 1,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <div
                                    style={{
                                      backgroundColor:
                                        'var(--p-color-bg-surface-selected)',
                                      padding: 'var(--p-space-300)',
                                      height: '125px',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <img src={layout.image} height={'100%'} />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </InlineGrid>
                  </BlockStack>
                </FormLayout>
              </FormLayout>
            </Box>
          </Card>
        ) : (
          <Card>
            <Banner>
              <Text>
                You are on older version of Glood.AI therefore template is not
                enabled for your store. In order to use template, kindly{' '}
                <Link onClick={openChat}>contact support</Link>.
              </Text>
            </Banner>
          </Card>
        )}
      </Layout.AnnotatedSection>
    </>
  )
}
