import React, { useEffect, useState } from 'react'
import {
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  InlineGrid,
  Layout,
  Select,
  Text,
  TextField,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { LockIcon, PlusIcon, XIcon } from '@shopify/polaris-icons'

import { areFeaturesEnabled } from '../../utils/features'
import { FEATURES } from '../../constants/features'
import LANGS from '../../constants/languages'
import CustomModal from '../CustomModal'

function TranslationModal({
  translationField,
  pricing,
  dashboardData,
  primaryTextField,
  disabled = false,
}) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const featureAvailable = areFeaturesEnabled(
    [FEATURES.TRANSLATIONS],
    dashboardData,
  )

  return (
    <>
      <Button
        disabled={disabled}
        icon={!featureAvailable && LockIcon}
        onClick={() => {
          if (featureAvailable) {
            setOpen(true)
          } else {
            pricing.open({
              features: [FEATURES.TRANSLATIONS],
            })
          }
        }}
      >
        {t('Section.Edit.Sections.TranslationConfig.FormFields.btnText')}
      </Button>
      <TranslateModal
        open={open}
        setOpen={setOpen}
        translationField={translationField}
        primaryTextField={primaryTextField}
      />
    </>
  )
}

export default TranslationModal

function TranslateModal({ translationField, open, setOpen, primaryTextField }) {
  const values = Object.entries(translationField.value).map(([key, value]) => ({
    language: key,
    value: value.title ?? value ?? '',
  }))
  const [translations, setTranslations] = useState(values)
  const { t } = useTranslation()

  const languageOptions = LANGS.map((lang) => ({
    label: lang.label,
    value: lang.value,
  }))

  useEffect(() => {
    const translationObject = translations.reduce(
      (acc, { language, value }) => {
        if (!language || !value) return acc
        if (!acc[language]) {
          acc[language] = {
            title: '',
          }
        }
        acc[language].title = value
        return acc
      },
      {},
    )
    if (
      JSON.stringify(translationObject) !==
      JSON.stringify(translationField.value)
    ) {
      translationField.onChange(translationObject)
    }
  }, [translations])

  return (
    <CustomModal
      show={open}
      setShow={setOpen}
      title={t('Section.Edit.Sections.TranslationConfig.modalTitle')}
    >
      <Box background="bg-fill">
        <BlockStack>
          <Box padding={'300'}>
            <InlineGrid columns={'200px auto'} gap={'300'}>
              <TextField
                value={
                  languageOptions.find(
                    ({ value }) =>
                      value === shopify.config.locale.split('-')[0],
                  )?.label
                }
                readOnly
                label={t(
                  'Section.Edit.Sections.TranslationConfig.FormFields.Language.label',
                )}
              />
              <TextField
                value={primaryTextField.value}
                readOnly
                label={t(
                  'Section.Edit.Sections.TranslationConfig.FormFields.Translation.label',
                )}
              />
            </InlineGrid>
          </Box>
          {translations.map(({ language, value }, index) => (
            <>
              <Box padding={'300'}>
                <InlineGrid columns={'200px auto'} gap={'300'}>
                  <Select
                    value={language}
                    onChange={(val) => {
                      translations[index].language = val
                      setTranslations([...translations])
                    }}
                    options={languageOptions}
                    placeholder={t(
                      'Section.Edit.Sections.TranslationConfig.FormFields.Language.placeholder',
                    )}
                    label={t(
                      'Section.Edit.Sections.TranslationConfig.FormFields.Language.label',
                    )}
                  />
                  <TextField
                    value={value}
                    onChange={(val) => {
                      translations[index].value = val
                      setTranslations([...translations])
                    }}
                    label={t(
                      'Section.Edit.Sections.TranslationConfig.FormFields.Translation.label',
                    )}
                    placeholder={t(
                      'Section.Edit.Sections.TranslationConfig.FormFields.Translation.placeholder',
                    )}
                    suffix={
                      <div
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                      >
                        <Button
                          icon={XIcon}
                          onClick={() => {
                            setTranslations(
                              translations.filter((_, i) => i !== index),
                            )
                          }}
                          size="large"
                          variant=""
                        />
                      </div>
                    }
                  />
                </InlineGrid>
              </Box>
              {index !== translations.length - 1 && <Divider />}
            </>
          ))}
          <div
            style={{
              padding: 'var(--p-space-300)',
            }}
          >
            <Button
              icon={PlusIcon}
              variant="primary"
              fullWidth
              onClick={() => {
                setTranslations([
                  ...translations,
                  {
                    language: '',
                    value: '',
                  },
                ])
              }}
            >
              {t('Section.Edit.Sections.TranslationConfig.addTranslate')}
            </Button>
          </div>
        </BlockStack>
      </Box>
    </CustomModal>
  )
}
