import { Button, Tooltip } from "@shopify/polaris";

export default function ButtonWithToolTip({
    tooltipText,
    tooltipProps = {},
    ...btnProps
}){
    if(!tooltipText){
        return <Button {...btnProps} />
    }

    return <Tooltip content={tooltipText} {...tooltipProps}>
        <Button {...btnProps} />
    </Tooltip>
}