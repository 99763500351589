import { useCallback, useContext, useEffect, useState } from 'react'

import { changePlan, getPlans } from '../../../apis/plans'
import queryClient from '../../../utils/query'
import { getFeaturesPlans } from '../../../utils/features'

export function usePricing({ plan = null } = {}) {
  const [modalOpen, setModalOpen] = useState(false)
  const [plans, setPlans] = useState([])
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const cb = () => {
      setModalOpen(false)
      queryClient.invalidateQueries(['getDashboardDetails'])
    }
    document.addEventListener('visibilitychange', cb)
    return () => {
      document.removeEventListener('visibilitychange', cb)
    }
  })

  const fetchPlans = useCallback(
    async (
      filter = {
        features: [],
      },
    ) => {
      if(filter.showContactCTA){
        setPlans([])
        return
      }
      setLoading(true)
      const { data, error } = await getPlans({
        ...filter,
        plan
      })
      setLoading(false)
      if (error) {
        setError(error)
        setPlans([])
        return
      }
      setError(null)
      const filteredPlans = getFeaturesPlans(
        filter.features,
        data,
        window.shopify.data,
      )
      setPlans((d) => [...filteredPlans])
    },
    [],
  )

  const modalOpenHandler = async (
    filter = {
      features: [],
      recommenadion: '',
      page: '',
      showContactCTA: false,
    },
  ) => {
    setLoading(true)
    await fetchPlans(filter)
    setModalOpen(true)
    setLoading(false)
  }

  useEffect(() => {
    fetchPlans()
    const cb = () => {
      fetchPlans()
    }

    document.addEventListener('visibilitychange', cb)
    return () => {
      document.removeEventListener('visibilitychange', cb)
    }
  }, [])

  return {
    isLoading: loading,
    isOpen: modalOpen,
    close: () => {
      fetchPlans()
      setModalOpen(false)
    },
    plans,
    open: modalOpenHandler,
    addPromoCode: (promo) => {
      return fetchPlans({ promo, plans })
    },
    showPlans: (plan) => fetchPlans({ plan }),
    changePlan: async (payload) => {
      const url = (await changePlan(payload)).data.redirectUrl
      await queryClient.invalidateQueries(['dashboardBoot'])
      return url
    },
    error,
  }
}
