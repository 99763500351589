import {
  Avatar,
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  IndexTable,
  InlineGrid,
  InlineStack,
  ResourceItem,
  ResourceList,
  Text,
  TextField,
  useIndexResourceState,
} from '@shopify/polaris'
import { DeleteIcon, PlusIcon } from '@shopify/polaris-icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const PAGE_SIZE = 5

function ObjectRecommendation({ objects, setObjects, error, setError }) {
  const { t } = useTranslation()
  return (
    <Card padding={'0'}>
      <Box padding={'400'}>
        <BlockStack gap={'100'}>
          <Text as="strong">
            {t(
              'Section.Edit.Sections.Recommendation.ObjectRecommendation.title',
            )}
          </Text>
          <Text>
            {t(
              'Section.Edit.Sections.Recommendation.ObjectRecommendation.description',
            )}
          </Text>
        </BlockStack>
      </Box>
      <Divider />
      {objects.map((object, index) => (
        <>
          <ObjectTable
            onProductDelete={(selectedIds) => {
              const products = object.products.filter(
                (p) => !selectedIds.includes(p.id),
              )
              const newObjects = [...objects]
              newObjects[index] = { ...object, products }
              setObjects(newObjects)
            }}
            onChange={(newObject) => {
              const newObjects = [...objects]
              newObjects[index] = newObject
              setObjects(newObjects)
            }}
            products={object.products}
            url={object.url}
            onRemove={() => {
              const newObjects = [...objects]
              newObjects.splice(index, 1)
              setObjects(newObjects)
            }}
            urls={objects.map((o) => o.url).filter((u, i) => i !== index)}
            error={error?.[index]}
            onError={(newError) => {
              setError(newError)
            }}
          />
          <Divider />
        </>
      ))}
      <Box padding={'400'}>
        <Button
          icon={PlusIcon}
          onClick={() => {
            setObjects([...objects, { url: '', products: [] }])
          }}
        >
          {t(
            'Section.Edit.Sections.Recommendation.ObjectRecommendation.addRecommendation',
          )}
        </Button>
      </Box>
    </Card>
  )
}

export default ObjectRecommendation

function ObjectTable({
  onProductDelete,
  onChange,
  products,
  url,
  onRemove,
  urls,
  error: errorProp,
  onError,
}) {
  const [error, setError] = useState(null)
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [page, setPage] = useState(1)
  const [selectedItems, setSelectedItems] = useState([])
  const { t } = useTranslation()

  useEffect(() => {
    const regex = /^\/(?:[a-zA-Z0-9\-._~%!$&'()*+,;=:@/])*$/
    if (regex.test(url) || url.length === 0) {
      setError(null)
    } else {
      setError(
        t(
          'Section.Edit.Sections.Recommendation.ObjectRecommendation.invalidUrl',
        ),
      )
    }
  })

  return (
    <>
      <Box padding={'400'}>
        <Text>
          {t(
            'Section.Edit.Sections.Recommendation.ObjectRecommendation.addUrlLabel',
          )}
        </Text>
        <InlineGrid columns={'1fr auto'} gap={'200'} alignItems="start">
          <TextField
            helpText={t(
              'Section.Edit.Sections.Recommendation.ObjectRecommendation.helpText',
            )}
            error={
              error ?? isDuplicate
                ? t(
                    'Section.Edit.Sections.Recommendation.ObjectRecommendation.enterUniqueUrls',
                  )
                : null
            }
            value={url}
            onChange={(value) => {
              const count = urls.filter((u) => u === value).length
              if (count > 0) {
                setIsDuplicate(true)
                onError(
                  t(
                    'Section.Edit.Sections.Recommendation.ObjectRecommendation.enterUniqueUrls',
                  ),
                )
              } else {
                setIsDuplicate(false)
                onError(null)
              }
              onChange({
                url: value,
                products,
              })
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: 'var(--p-space-200)',
            }}
          >
            <Button
              icon={DeleteIcon}
              size="micro"
              onClick={() => {
                onRemove()
              }}
            />
            <Button
              onClick={async () => {
                try {
                  const prods = await shopify.resourcePicker({
                    type: 'product',
                    selectionIds: products.map((p) => ({
                      id: 'gid://shopify/Product/' + p.id,
                    })),
                    multiple: 20,
                    filter: {
                      variants: false,
                      archived: false,
                    },
                  })
                  if (prods) {
                    onChange({
                      url,
                      products: prods.map((p) => ({
                        images: p.featuredImage?.url
                          ? [p.featuredImage?.url]
                          : [],
                        title: p.title,
                        vendor: p.vendor,
                        handle: p.handle,
                        id: parseInt(
                          p.id.replace('gid://shopify/Product/', ''),
                        ),
                      })),
                    })
                  }
                } catch (e) {
                  window.shopify.toast.show(t('noProductUpdate'), {
                    isError: true,
                  })
                }
              }}
              disabled={error || url.length === 0}
            >
              {t(
                'Section.Edit.Sections.Recommendation.ObjectRecommendation.addCta',
              )}
            </Button>
          </div>
        </InlineGrid>
      </Box>
      <Divider />
      <ResourceList
        resourceName={{
          singular: 'product',
          plural: 'products',
        }}
        items={products.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)}
        renderItem={renderItem}
        selectedItems={selectedItems}
        onSelectionChange={setSelectedItems}
        resolveItemId={resolveItemIds}
        pagination={{
          hasPrevious: page > 1,
          hasNext: products.length > page * PAGE_SIZE,
          onPrevious: () => setPage((prev) => prev - 1),
          onNext: () => setPage((prev) => prev + 1),
        }}
        promotedBulkActions={[
          {
            content: t(
              'Section.Edit.Sections.Recommendation.ObjectRecommendation.deleteCta',
            ),
            onAction: () => onProductDelete(selectedItems),
            icon: DeleteIcon,
          },
        ]}
        emptyState={
          <Box padding={'400'}>
            {' '}
            <InlineStack alignment="center">
              <Text>
                {t('Section.Details.ObjectRecommendation.emptyState')}
              </Text>
            </InlineStack>
          </Box>
        }
      />
    </>
  )
}

function renderItem(product, _, index) {
  const media = (
    <Avatar
      size="sm"
      name={product.title}
      source={
        product.images?.[0] ||
        'https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?v=1530129081'
      }
    />
  )

  return (
    <ResourceItem id={product.id} media={media} sortOrder={index}>
      <BlockStack>
        <Text as="strong">{product.title}</Text>
        <Text>ID: {product.id}</Text>
      </BlockStack>
    </ResourceItem>
  )
}

function resolveItemIds({ id }) {
  return id
}
