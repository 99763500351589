import {
  ActionList,
  Box,
  Divider,
  Form,
  InlineGrid,
  Popover,
  ResourceItem,
  ResourceList,
  Text,
  TextField,
  Avatar,
  Badge,
} from '@shopify/polaris'
import {
  DeleteIcon,
  PlusIcon,
  ProductIcon,
  ChevronDownIcon,
  DiscountIcon,
  DiscountFilledIcon,
} from '@shopify/polaris-icons'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import { Component } from 'react'
import { forwardRef, useState, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import OnOutsideClick from 'react-outclick'
import { canAccessResourcePicker } from '../../utils/access'
import Button from '../Button'

const MAX_RECOMMENDATION = 10

const RecommendationsProductSelector = forwardRef(ProductSelector)

export default RecommendationsProductSelector

function ProductSelector({
  productsOnAdd = () => {},
  selectedItems,
  setSelectedItems,
  form,
  discounts,
  handlePopoverChange,
  ref,
  skipPopover = false,
  productsOnChange,
  productsLoading,
}) {
  const { t } = useTranslation()
  ref = ref ?? useRef()

  const bulkAction = () => {
    const newList = form.fields.products.value.filter(
      (item) => !selectedItems.find((productId) => productId === item.id),
    )
    form.fields.products.onChange(newList)
    setSelectedItems([])
  }

  useImperativeHandle(
    ref,
    () => {
      return {}
    },
    [],
  )

 const canPickProducts = canAccessResourcePicker(window.shopify.data)

  return (
    <ResourceList
      loading={productsLoading}
      alternateTool={
        <InlineGrid columns={'auto auto'} alignItems="center" gap={'300'}>
          {skipPopover || (
            <ProductSelectionPopover
              disabled={form.fields.products.value.length >= MAX_RECOMMENDATION}
              onChange={handlePopoverChange}
              canPickProducts={canPickProducts}
            />
          )}
        </InlineGrid>
      }
      items={form.fields.products.value}
      resourceName={{
        singular: 'product',
        plural: 'products',
      }}
      emptyState={
        <Box padding={'800'}>
          <InlineGrid columns={'1fr auto 1fr'}>
            <div />
            <div
              style={{
                display: 'flex',
                gap: 'var(--p-space-300)',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Text as="strong">
                {t(
                  'CheckoutUpsell.CheckoutUpsellConfig.Recommendation.card.emptyText',
                )}
              </Text>
              <ProductSelectionPopover
                primaryVariant
                onChange={handlePopoverChange}
                canPickProducts={canPickProducts}
              />
            </div>
            <div />
          </InlineGrid>
        </Box>
      }
      renderItem={ProductCard({
        t,
        discounts,
        onChange: productsOnChange,
        onAdd: productsOnAdd,
      })}
      selectedItems={selectedItems}
      onSelectionChange={setSelectedItems}
      selectable
      promotedBulkActions={[
        {
          icon: DeleteIcon,
          destructive: true,
          content: 'Delete',
          onAction: bulkAction,
        },
      ]}
    />
  )
}

export function ProductSelectionPopover({
  onChange,
  disabled,
  primaryVariant = false,
  canPickProducts
}) {
  const [popoverActive, setPopoverActive] = useState(false)
  const { t } = useTranslation()

  return (
    <Popover
      active={popoverActive}
      activator={
        <Button
          icon={PlusIcon}
          variant={primaryVariant ? 'primary' : 'secondary'}
          disabled={disabled || !canPickProducts}
          onClick={() => {
            setPopoverActive(true)
          }}
          tooltipText={!canPickProducts && t('noProductPermission')}
        >
          {t('CheckoutUpsell.CheckoutUpsellConfig.ProductSelectionPopover.cta')}
        </Button>
      }
      autofocusTarget="first-node"
      onClose={() => {
        setPopoverActive(false)
      }}
    >
      <Popover.Pane>
        {' '}
        <ActionList
          actionRole="menuitem"
          items={[
            {
              content: t(
                'CheckoutUpsell.CheckoutUpsellConfig.ProductSelectionPopover.specific_products.label',
              ),
              onAction: async () => {
                await onChange({
                  specificProducts: true,
                })
                setPopoverActive(false)
              },
            },
            {
              content: t(
                'CheckoutUpsell.CheckoutUpsellConfig.ProductSelectionPopover.aiRec.label',
              ),
              onAction: async () => {
                await onChange({
                  aiRec: 1,
                })
                setPopoverActive(false)
              },
            },
          ]}
        />
      </Popover.Pane>
    </Popover>
  )
}

const ProductCard =
  ({ t, discounts, onChange = () => {}, onAdd }) =>
  (item) => {
    const {
      title = t(
        'CheckoutUpsell.CheckoutUpsellConfig.Recommendation.card.aiGenerateBadge',
      ),
      id,
      images,
      aiGenerated,
      discount,
    } = item
    const imageSrc =
      images?.length > 0 ? images[0].originalSrc ?? images[0] : ProductIcon
    return (
      <ResourceItem
        id={id}
        media={<Avatar size="md" name={title} source={imageSrc} />}
        name={title}
      >
        <InlineGrid columns={'1fr auto'} gap={'300'} alignItems="start">
          <Text variant="bodyMd" fontWeight="bold" as="h3">
            {title}
          </Text>
          {aiGenerated && (
            <div>
              <Badge tone="info">
                {t(
                  'CheckoutUpsell.CheckoutUpsellConfig.Recommendation.card.aiGenerateBadge',
                )}
              </Badge>
            </div>
          )}
        </InlineGrid>
        <div
          style={{
            paddingTop: 'var(--p-space-50)',
          }}
        >
          {/* <DiscountSelector
              discounts={discounts}
              discount={discount}
              t={t}
              onClick={(d) => {
                if (item.discount) {
                  onChange({
                    ...item,
                    discount: undefined,
                  })
                } else {
                  onChange({
                    ...item,
                    discount: d,
                  })
                }
              }}
              onAdd={onAdd}
            /> */}
          <Text>
            {aiGenerated
              ? t('aiGenerated')
              : `ID: ${item.id} | Vendor: ${item.vendor}`}
          </Text>
        </div>
      </ResourceItem>
    )
  }
