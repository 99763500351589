import {
  BlockStack,
  Box,
  Form,
  Text,
  TextField,
  InlineStack,
  Button,
  Select,
} from '@shopify/polaris'
import { useForm, useField } from '@shopify/react-form'
import { useTranslation } from 'react-i18next'

import { updateShopConfig } from '../../../../../apis/dashbboard'
import { SECTION_DISCOUNT } from '../../../../../constants/discount'

export default function Discount({ dashboardData }) {
  const { t } = useTranslation()
  const form = useForm({
    fields: {
      discountLabel: useField(dashboardData.config.data?.sectionDiscountTitle),
      strategy: useField(
        dashboardData.config.data?.strategy ??
          SECTION_DISCOUNT.ALL,
      ),
    },
    onSubmit: async (data) => {
      const payload = {
        data: {
          ...dashboardData.config.data,
          sectionDiscountTitle: data.discountLabel,
          strategy: data.strategy,
        },
      }

      const { error } = await updateShopConfig(payload)

      if (error) {
        return {
          status: 'fail',
          errors: [
            {
              message: error,
            },
          ],
        }
      }
      shopify.toast.show(
        t('DefaultText.updateSuccess', {
          text: t('DefaultText.settings'),
        }),
      )
      return {
        status: 'success',
      }
    },
  })

  return (
    <Form onSubmit={form.submit}>
      <BlockStack gap={'300'}>
        <Box>
          <Text as="strong">
            {t('SettingsPage.Sections.Advanced.Section.discount.title')}
          </Text>
        </Box>
        <Box padding={'100'}>
          <BlockStack gap="400">
            <TextField
              {...form.fields.discountLabel}
              label={t('Section.Edit.Sections.DiscountConfig.form.label.label')}
              placeholder={t(
                'Section.Edit.Sections.DiscountConfig.form.label.placeholder',
              )}
              helpText={t(
                'Section.Edit.Sections.DiscountConfig.form.label.helpText',
              )}
            />
            <Select
              {...form.fields.strategy}
              label={t(
                'SettingsPage.Sections.Advanced.Section.discountStrategy.label',
              )}
              helpText={
                <BlockStack gap="100">
                  {Object.values(
                    t(
                      'SettingsPage.Sections.Advanced.Section.discountStrategy.helpText',
                    ),
                  ).map((text) => (
                    <Text key={text}>{text}</Text>
                  ))}
                </BlockStack>
              }
              options={Object.values(SECTION_DISCOUNT).map((value) => ({
                label: t(
                  `SettingsPage.Sections.Advanced.Section.discountStrategy.${value}`,
                ),
                value,
              }))}
            />
          </BlockStack>
        </Box>
        <InlineStack align="end">
          <Button loading={form.submitting} submit>
            {t('SettingsPage.Sections.Advanced.Section.js.btnCta')}
          </Button>
        </InlineStack>
      </BlockStack>
    </Form>
  )
}
