import {
  BlockStack,
  Box,
  Card,
  Grid,
  SkeletonBodyText,
  SkeletonDisplayText,
  Spinner,
  InlineStack,
  Text,
  Badge,
  Tooltip,
  DataTable,
  SkeletonThumbnail,
} from '@shopify/polaris'
import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Line } from 'react-chartjs-2'

Chart.register(CategoryScale)

import { useLocalSettings } from '../../../../components/LocalSettings'
import {
  currencyFormatter,
  numberFormater,
  percentageFormatter,
} from '../../../../utils/formater'
import { format } from 'date-fns'
import DateTimeRangePopover from '../../../../components/DateTimeRangePopover'
import { getRecommendationStats } from '../../../../apis/analytics'

export default function StoreAnanlytics() {
  const { settings, saveSettings } = useLocalSettings()
  const [durationFilter, setDurationFilter] = useState({
    start: new Date(
      Math.max(
        new Date('2024-12-10').getTime(),
        new Date().getTime() - 30 * 24 * 60 * 60 * 1000,
      ),
    ),
    end: new Date(new Date().setHours(23, 59, 59, 999)),
  })
  const { t } = useTranslation()
  const analyticsQuery = useQuery({
    queryKey: ['store-analytics', durationFilter],
    queryFn: () => {
      return getRecommendationStats({
        dateGte: new Date(durationFilter.start).toISOString().split('T')[0],
        dateLte: new Date(durationFilter.end.setHours(23, 59, 59, 999))
          .toISOString()
          .split('T')[0],
      })
    },
  })

  useEffect(() => {
    saveSettings({ durationFilter })
  }, [durationFilter])

  const cards = getCards(analyticsQuery.data?.data, t, analyticsQuery.isLoading)

  return (
    <Wrapper>
      {cards.map((card, index) => (
        <AnalyticsCard key={index} {...card} />
      ))}
    </Wrapper>
  )

  function Wrapper({ children }) {
    return (
      <BlockStack gap={'200'}>
        <InlineStack align="end">
          {/* <Badge tone="attention">{t('DefaultText.beta')}</Badge> */}
          <DateTimeRangePopover
            disableDatesBefore={new Date('2024-12-09')}
            handleDurationFilterChange={({ start, end }) => {
              setDurationFilter({
                start,
                end,
              })
            }}
            durationFilter={durationFilter}
            template="MMM dd, yyyy"
          />
        </InlineStack>
        <Grid
          gap={{
            xs: 'var(--p-space-400)',
            sm: 'var(--p-space-400)',
            md: 'var(--p-space-400)',
            lg: 'var(--p-space-400)',
            xl: 'var(--p-space-400)',
          }}
          columns={{
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 3,
          }}
        >
          {children}
        </Grid>
        <Box padding={'400'} />
      </BlockStack>
    )
  }
}

function AnalyticsCard({
  label,
  value,
  graphLabels,
  total,
  formatter,
  tooltip,
  type,
  tableRows,
  graphDataSets,
  loading,
}) {
  if (type === 'daily') {
    return (
      <Grid.Cell>
        <Card padding={'0'}>
          <BlockStack gap={'200'}>
            <Header />
            <div
              style={{
                height: '300px',
                padding: 'var(--p-space-200)',
              }}
            >
              {loading && (
                <div
                  style={{
                    padding:
                      '0 var(--p-space-200) var(--p-space-200) var(--p-space-200)',
                      height: '100%',
                      width: '100%',
                  }}
                >
                  <Skeleton size="small" />
                </div>
              )}
              {!loading && (
                <Line
                  data={{
                    labels: graphLabels,
                    datasets: graphDataSets,
                  }}
                  width={'100%'}
                  options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    elements: {
                      line: {
                        tension: 0.3,
                        borderJoinStyle: 'round',
                        hoverBorderJoinStyle: 'round',
                      },
                    },
                    plugins: {
                      legend: {
                        position: 'bottom',
                        align: 'end',
                        labels: {
                          usePointStyle: true,
                          pointStyle: 'line',
                          sort: (a, b) => {
                            return a.datasetIndex - b.datasetIndex
                          },
                        },
                      },
                      tooltip: {
                        mode: 'x',
                        backgroundColor: 'white',
                        titleColor: 'rgba(26, 26, 26, 1)',
                        bodyColor: 'rgba(48, 48, 48, 1)',
                        borderWidth: 1,
                        borderColor: 'rgba(0, 0, 0, 0.1)',
                        intersect: false,
                        callbacks: {
                          label: (tooltipItem) => {
                            return (
                              tooltipItem.dataset.label +
                              ': ' +
                              formatter(tooltipItem.parsed.y)
                            )
                          },
                        },
                        usePointStyle: true,
                      },
                    },
                    hover: {
                      mode: 'nearest',
                      intersect: true,
                    },
                    tooltips: {
                      mode: 'x',
                      intersect: false,
                    },
                    scales: {
                      xAxes: [
                        {
                          display: true,
                          scaleLabel: {
                            display: true,
                            labelString: 'Month',
                          },
                        },
                      ],
                      yAxes: [
                        {
                          display: true,
                          scaleLabel: {
                            display: true,
                          },
                        },
                      ],
                      y: {
                        ticks: {
                          callback: function (value, index, ticks) {
                            return formatter(value).replace(/\.\d+/, '')
                          },
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
          </BlockStack>
        </Card>
      </Grid.Cell>
    )
  }

  if (type === 'stats') {
    return (
      <Grid.Cell>
        <Card padding={'0'}>
          <BlockStack gap={'200'}>
            <Header />
            <div
              style={{
                height: '300px',
              }}
            >
              {loading ? (
                <div style={{
                  padding: 'var(--p-space-200) var(--p-space-400) var(--p-space-400) var(--p-space-400)',
                  height: '100%',
                  width: '100%',
                }}>
                  <Skeleton size="small" />
                  </div>
              ) : (
                <DataTable
                  columnContentTypes={['text', 'text']}
                  headings={[]}
                  rows={createRows()}
                />
              )}
            </div>
          </BlockStack>
        </Card>
      </Grid.Cell>
    )

    function createRows() {
      if (!tableRows) {
        new Error('Analytics:StoreAnalytics, Table rows not provided')
      }

      return tableRows.map((row) => {
        return row.map((cell, index) => {
          const wrapper = (content) =>
            index == 0 ? (
              <div
                style={{
                  paddingLeft: 'var(--p-space-200)',
                }}
              >
                {content}
              </div>
            ) : (
              content
            )
          if (cell.label) {
            return wrapper(
              <BlockStack>
                <Text truncate tone="base">
                  {cell.label}
                </Text>
                <Text truncate tone="subdued" variant="bodyMd">
                  {cell.value}
                </Text>
              </BlockStack>,
            )
          }

          return wrapper(<Text>{cell.value}</Text>)
        })
      })
    }
  }

  return null

  function Header() {
    return (
      <div>
        <div
          onMouseOver={(e) => {
            e.currentTarget.style.backgroundColor =
              'var(--p-color-bg-surface-hover)'
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.backgroundColor = 'transparent'
          }}
          style={{
            transition: 'all 0.1s var(--p-motion-ease-in-out)',
            cursor: 'pointer',
            padding:
              'var(--p-space-400) var(--p-space-400) var(--p-space-200)  var(--p-space-400)',
          }}
        >
          <Tooltip preferredPosition="below" width="wide" content={tooltip}>
            <Text as="h4" variant="headingMd">
              <span
                style={{
                  textDecoration: 'underline',
                  textDecorationStyle: 'dashed',
                  textDecorationColor: 'var(--p-color-bg-fill-selected)',
                  textDecorationThickness: '2px',
                }}
              >
                {label}
              </span>
            </Text>
          </Tooltip>
        </div>
        <div
          style={{
            padding: '0 var(--p-space-400)',
          }}
        >
          {loading ? (
            <SkeletonDisplayText />
          ) : (
            <InlineStack gap={'200'}>
              <Text variant="headingLg" as="strong">
                {value ?? 0}
              </Text>
              {total && (
                <Text variant="subdued" as="span">
                  / {total}
                </Text>
              )}
            </InlineStack>
          )}
        </div>
      </div>
    )
  }

  function Skeleton() {
    return (
      <div
        style={{
          backgroundColor: 'var(--p-color-bg-fill-tertiary)',
          height: '100%',
          width: '100%',
          borderRadius: 'var(--p-border-radius-100)',
        }}
      />
    )
  }
}

function getCards(data, t, loading) {
  const graphLabels = data?.dailyMetrics?.map((d) =>
    format(new Date(d.date), 'MMM dd'),
  )

  return [
    {
      label: t('StoreAnalytics.purchaseRate.title'),
      value: percentageFormatter(data?.metrics?.recommendationConversionRate),
      total: null,
      type: 'stats',
      loading,
      tableRows: [
        [
          {
            label: t('StoreAnalytics.purchaseRate.tableRows.label1'),
            value:
              numberFormater(data?.metrics?.sessionsWithRecommendations) +
              (data?.metrics?.sessionsWithRecommendations < 2
                ? ' session'
                : ' sessions'),
          },
          {
            label: null,
            value: percentageFormatter(data?.metrics?.recommendationViewRate),
          },
        ],
        [
          {
            label: t('StoreAnalytics.purchaseRate.tableRows.label2'),
            value:
              numberFormater(data?.metrics?.sessionsWithInteractions) +
              (data?.metrics?.sessionsWithInteractions < 2
                ? ' session'
                : ' sessions'),
          },
          {
            label: null,
            value: percentageFormatter(data?.metrics?.recommendationCtr),
          },
        ],
        [
          {
            label: t('StoreAnalytics.purchaseRate.tableRows.label3'),
            value:
              numberFormater(
                data?.metrics?.sessionsWithRecommendationsAndPurchase,
              ) +
              (data?.metrics?.sessionsWithRecommendationsAndPurchase < 2
                ? ' session'
                : ' sessions'),
          },
          {
            label: null,
            value: percentageFormatter(
              data?.metrics?.recommendationConversionRate,
            ),
          },
        ],
      ],
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('StoreAnalytics.purchaseRate.title')}
            </Text>
            <Text variant="bodySm">
              {t('StoreAnalytics.purchaseRate.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
    },
    {
      label: t('StoreAnalytics.purchaseRateOverTime.label'),
      value: percentageFormatter(data?.metrics?.recommendationConversionRate),
      total: null,
      type: 'daily',
      graphLabels,
      graphDataSets: [
        {
          label: t('StoreAnalytics.purchaseRateOverTime.label1'),
          data: data?.dailyMetrics?.map((d) => d.recommendationCtr),
        },
        {
          label: t('StoreAnalytics.purchaseRateOverTime.label3'),
          data: data?.dailyMetrics?.map((d) => d.conversionRate),
        },
        {
          label: t('StoreAnalytics.purchaseRateOverTime.label2'),
          data: data?.dailyMetrics?.map((d) => d.recommendationConversionRate),
        },
      ],
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('StoreAnalytics.purchaseRateOverTime.label')}
            </Text>
            <Text variant="bodySm">
              {t('StoreAnalytics.purchaseRateOverTime.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
      formatter: percentageFormatter,
      loading,
    },
    {
      label: t('StoreAnalytics.attributedRevenue.title'),
      description: t('StoreAnalytics.attributedRevenue.description'),
      value: currencyFormatter(
        window.shopify.data.shop.currency,
        data?.metrics?.totalAmountWithRecommendationPurchases,
      ),
      formatter: (val) =>
        currencyFormatter(window.shopify.data.shop.currency, val),
      type: 'daily',
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('StoreAnalytics.attributedRevenue.title')}
            </Text>
            <Text variant="bodySm">
              {t('StoreAnalytics.attributedRevenue.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
      graphLabels,
      graphDataSets: [
        {
          label: t('StoreAnalytics.attributedRevenue.title'),
          data: data?.dailyMetrics?.map(
            (d) => d.totalAmountWithRecommendationPurchases,
          ),
        },
      ],
      loading,
    },
    {
      label: t('StoreAnalytics.attributedRevenueTable.label'),
      description: t('StoreAnalytics.attributedRevenueTable.description'),
      value: percentageFormatter(
        data?.metrics?.recommendationRevenueContribution,
      ),
      type: 'stats',
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('StoreAnalytics.attributedRevenueTable.label')}
            </Text>
            <Text variant="bodySm">
              {t('StoreAnalytics.attributedRevenueTable.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
      tableRows: [
        [
          {
            label: t('StoreAnalytics.attributedRevenue.tableRows.label1'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data?.metrics?.totalAmount,
            ),
          },
        ],
        [
          {
            label: t('StoreAnalytics.attributedRevenue.tableRows.label2'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data?.metrics?.totalAmountWithRecommendations,
            ),
          },
        ],
        [
          {
            label: t('StoreAnalytics.attributedRevenue.tableRows.label3'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data?.metrics?.totalAmountWithRecommendationPurchases,
            ),
          },
        ],
      ],
      loading,
    },
    {
      label: t('StoreAnalytics.aov.title'),
      value: currencyFormatter(
        window.shopify.data.shop.currency,
        data?.metrics?.aovWithRecommendationPurchases,
      ),
      graphDataSets: [
        {
          label: t('StoreAnalytics.aovTable.tableRows.label3'),
          data: data?.dailyMetrics?.map(
            (d) => d.aovWithRecommendationPurchases,
          ),
        },
        {
          label: t('StoreAnalytics.aovTable.tableRows.label2'),
          data: data?.dailyMetrics?.map((d) => d.aovWithRecommendations),
        },

        {
          label: t('StoreAnalytics.aovTable.tableRows.label1'),
          data: data?.dailyMetrics?.map((d) => d.aov),
        },
      ],
      total: null,
      formatter: (val) =>
        currencyFormatter(window.shopify.data.shop.currency, val),
      type: 'daily',
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">{t('StoreAnalytics.aov.title')}</Text>
            <Text variant="bodySm">{t('StoreAnalytics.aov.description')}</Text>
          </BlockStack>
        </Box>
      ),
      graphLabels,
      loading,
    },
    {
      label: t('StoreAnalytics.aovTable.title'),
      value: percentageFormatter(
        data?.metrics?.aovIncreaseDueToRecommendations,
      ),
      tableRows: [
        [
          {
            label: t('StoreAnalytics.aovTable.tableRows.label1'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data?.metrics?.aov,
            ),
          },
        ],
        [
          {
            label: t('StoreAnalytics.aovTable.tableRows.label2'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data?.metrics?.aovWithRecommendations,
            ),
          },
        ],
        [
          {
            label: t('StoreAnalytics.aovTable.tableRows.label3'),
            value: null,
          },
          {
            label: null,
            value: currencyFormatter(
              window.shopify.data.shop.currency,
              data?.metrics?.aovWithRecommendationPurchases,
            ),
          },
        ],
      ],
      type: 'stats',
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('StoreAnalytics.aovTable.title')}
            </Text>
            <Text variant="bodySm">
              {t('StoreAnalytics.aovTable.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
      loading,
    },
    {
      label: t('StoreAnalytics.checkout.label'),
      value: numberFormater(data?.metrics?.checkoutCount),
      graphDataSets: [
        {
          label: t('StoreAnalytics.checkout.label1'),
          data: data?.dailyMetrics?.map((d) => d.checkoutCount),
        },
        {
          label: t('StoreAnalytics.checkout.label2'),
          data: data?.dailyMetrics?.map(
            (d) => d.checkoutCountWithRecommendations,
          ),
        },
        {
          label: t('StoreAnalytics.checkout.label3'),
          data: data?.dailyMetrics?.map(
            (d) => d.checkoutCountWithRecommendationPurchases,
          ),
        },
      ],
      formatter: numberFormater,
      type: 'daily',
      tooltip: (
        <Box padding={'100'} borderRadius="400">
          <BlockStack gap={'050'}>
            <Text variant="headingSm">
              {t('StoreAnalytics.checkout.label')}
            </Text>
            <Text variant="bodySm">
              {t('StoreAnalytics.checkout.description')}
            </Text>
          </BlockStack>
        </Box>
      ),
      graphLabels,
      loading,
    },
  ]
}
