import React, { useCallback, useEffect, useState } from 'react'
import {
  Autocomplete,
  Card,
  Divider,
  Icon,
  InlineGrid,
  InlineStack,
  ResourceItem,
  ResourceList,
  Tag,
  Text,
  Avatar,
  TextField,
  BlockStack,
  Banner,
  Box,
} from '@shopify/polaris'
import { DeleteIcon, PlusIcon, SearchIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'

import { canAccessResourcePicker } from '../../utils/access'
import Button from '../Button'

const PAGE_WINDOW_SIZE = 5

export default function ProductTagList({
  productSelectionTitle,
  productSelectionButtonCta,
  productSelectionDescription,
  products,
  onProductSelectionChange,
  tagsTitle,
  tagsDescription,
  tags = [],
  onTagChange,
  queryPlaceholder,
  tagBtnCta,
  separatedLayout = false,
  helpText = null,
}) {
  const { t } = useTranslation()
  const [selectedProducts, setSelectedProducts] = useState([])
  const [page, setPage] = useState(0)
  const [query, setQuery] = useState('')
  const [error, setError] = useState(null)

  const updateTagText = useCallback((value) => {
    setQuery(value)
  }, [])

  const canPickProducts = canAccessResourcePicker(window.shopify.data)

  return (
    <LayoutWrapper
      separated={separatedLayout}
      helpText={helpText}
      productsComponent={
        <>
          <div
            style={{
              padding: 'var(--p-space-400)',
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Text as="strong">{productSelectionTitle}</Text>
              <Text>{productSelectionDescription}</Text>
            </div>
            <Button
              toolTipText={
                !canPickProducts ? t('noProductPermission') : undefined
              }
              onClick={async () => {
                try {
                  const selected = await shopify.resourcePicker({
                    type: 'product',
                    multiple: 20,
                    selectionIds: (products ?? []).map(({ id }) => ({
                      id:
                        typeof id === 'number'
                          ? 'gid://shopify/Product/' + id
                          : !id.includes('gid://shopify/Product/')
                            ? 'gid://shopify/Product/' + id
                            : id,
                    })),
                    filter: {
                      variants: false,
                      archived: false,
                    },
                  })
                  if (selected) {
                    const processed = new Array(...selected).map((product) => ({
                      aiGenerated: false,
                      handle: product.handle,
                      id: product.id,
                      images: product.images.map(
                        ({ originalSrc }) => originalSrc,
                      ),
                      title: product.title,
                      vendor: product.vendor,
                    }))
                    onProductSelectionChange(processed)
                  } else {
                    onProductSelectionChange(products ?? [])
                  }
                } catch (e) {
                  window.shopify.toast.show(t('noProductUpdate'), {
                    isError: true,
                  })
                }
              }}
              disabled={!canPickProducts}
            >
              {productSelectionButtonCta}
            </Button>
          </div>
          <Divider borderColor="border" />
          {products?.length > 0 && (
            <>
              <ResourceList
                selectable={canPickProducts}
                resourceName={{ singular: 'product', plural: 'products' }}
                items={products.slice(
                  PAGE_WINDOW_SIZE * page,
                  PAGE_WINDOW_SIZE * (page + 1),
                )}
                pagination={{
                  hasPrevious: page > 0,
                  hasNext: products.length > PAGE_WINDOW_SIZE * (page + 1),
                  onNext: () => setPage(page + 1),
                  onPrevious: () => setPage(page - 1),
                }}
                renderItem={ProductCard}
                promotedBulkActions={
                  canPickProducts
                    ? [
                        {
                          icon: DeleteIcon,
                          destructive: true,
                          content: 'Delete',
                          onAction: () => {
                            const newList = products.filter(
                              (item) =>
                                !selectedProducts.find(
                                  (productId) => productId === item.id,
                                ),
                            )
                            onProductSelectionChange(newList)
                            setSelectedProducts([])
                          },
                        },
                      ]
                    : []
                }
                selectedItems={canPickProducts ? selectedProducts : undefined}
                onSelectionChange={setSelectedProducts}
              />
            </>
          )}
        </>
      }
      tagsComponent={
        <>
          <div
            style={{
              paddingTop: 'var(--p-space-400)',
              paddingLeft: 'var(--p-space-400)',
              paddingRight: 'var(--p-space-400)',
            }}
          >
            <Text as="strong">{tagsTitle}</Text>
            <Text>{tagsDescription}</Text>
          </div>
          <div
            style={{
              padding: 'var(--p-space-400)',
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 'var(--p-space-200)',
              }}
            >
              <InlineGrid columns={'1fr auto'} gap={'200'}>
                <TextField
                  onChange={(val) => {
                    setError(null)
                    updateTagText(val)
                  }}
                  value={query}
                  prefix={<Icon source={SearchIcon} tone="base" />}
                  placeholder={queryPlaceholder}
                  autoComplete="off"
                  error={error}
                />
                <div>
                  <Button
                    icon={PlusIcon}
                    onClick={() => {
                      if (query === '') {
                        setError(t('ProductTagList.emptyFieldText'))
                        return
                      }
                      onTagChange([...tags, query])
                      setQuery('')
                    }}
                  >
                    {tagBtnCta}
                  </Button>
                </div>
              </InlineGrid>
              <InlineStack gap={'200'}>
                {tags?.map((tag) => (
                  <Tag
                    onRemove={() => {
                      const copy = tags?.filter((value) => value !== tag) ?? []
                      onTagChange(copy)
                    }}
                  >
                    {tag}
                  </Tag>
                ))}
              </InlineStack>
            </div>
          </div>
        </>
      }
    />
  )
}

const ProductCard = (item) => {
  const { title, handle, id, url, images } = item
  const imageSrc =
    images.length > 0
      ? typeof images[0] === 'string'
        ? images[0]
        : images[0].src
      : 'https://boltagency.ca/content/images/2020/03/placeholder-images-product-1_large.png'
  return (
    <ResourceItem
      id={id}
      url={url}
      media={<Avatar size="md" name={title} source={imageSrc} />}
      accessibilityLabel={`Remove ${title}`}
      name={title}
    >
      {title}
      <Text variant="bodyMd" fontWeight="bold" as="h3">
        {title}
      </Text>
      <div>
        ID{' '}
        {typeof id === 'string' ? id.replace('gid://shopify/Product/', '') : id}
        {' | '}Handle {handle}
      </div>
    </ResourceItem>
  )
}

function LayoutWrapper({
  tagsComponent,
  productsComponent,
  separated = false,
  helpText,
}) {
  if (!separated) {
    return (
      <Card padding={'0'}>
        <BlockStack gap={'0'}>
          {helpText && (
            <div
              style={{
                padding:
                  'var(--p-space-400) var(--p-space-400) 0 var(--p-space-400) ',
              }}
            >
              <Banner>{helpText}</Banner>
            </div>
          )}
          <Box padding={'0'}>
            {tagsComponent}
            <Divider borderColor="border" />
            {productsComponent}
          </Box>
        </BlockStack>
      </Card>
    )
  }

  return (
    <BlockStack gap={'400'}>
      {helpText && (
        <div
          style={{
            padding:
              'var(--p-space-400) var(--p-space-400) 0 var(--p-space-400) ',
          }}
        >
          <Banner>{helpText}</Banner>
        </div>
      )}
      <Card padding={'0'}>{tagsComponent}</Card>
      <Card padding={'0'}>{productsComponent}</Card>
    </BlockStack>
  )
}
