import {
  BlockStack,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  InlineGrid,
  Layout,
  Text,
} from '@shopify/polaris'

import { CartDiscountIcon } from '@shopify/polaris-icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ProductSelectorModal from '../../../../components/ProductSelectorModal'
import AddDiscountModal from '../../../../components/AddDiscountModal'
import RecommendationSettingsModal from '../../../../components/RecommendationSettingModal'
import { BOUGHT_TOGETHER } from '../../../../constants/widgets/index.js'
import RecommendationsProductSelector from '../../../../components/RecommendationsProductSelector/index.jsx'

const MAX_RECOMMENDATION = 50

function RecommedationsSection({ form, productsLoading }) {
  const { t } = useTranslation()
  const [fbtModalOpen, setFbtModalOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])
  const [productSeqEditModalOpen, setProductSeqEditModalOpen] = useState(false)
  const recProductsHandlePopoverChange = async (value) => {
    let prods = [],
      copy = [...form.fields.products.value]
    if (value.aiRec) {
      Array.from({ length: value.aiRec }).forEach((_, index) => {
        prods.push({
          id: `ai-${parseInt(Math.random() * (10e13).toFixed(0))}`,
          aiGenerated: true,
          title: t(
            'CheckoutUpsell.CheckoutUpsellConfig.Recommendation.card.aiGenerate',
          ),
          images: [
            {
              originalSrc: CartDiscountIcon,
            },
          ],
          discount: null,
        })
      })
    } else if (value.specificProducts) {
      const resourcePickerPayload = {
        multiple:
          MAX_RECOMMENDATION -
          form.fields.products.value.reduce(
            (acc, p) => acc + (p.aiGenerated ? 1 : 0),
            0,
          ),
        type: 'product',
        selectedIds: form.fields.products.value
          .filter(({ aiGenerated }) => !aiGenerated)
          .map((p) => 'gid://shopify/Product/' + p.id),
        filter: {
          variants: false,
          archived: false,
        },
      }
      try {
        const selected = await shopify.resourcePicker(resourcePickerPayload)
        if (selected) {
          selected.forEach((product) => {
            prods.push({
              id: parseInt(product.id.replace('gid://shopify/Product/', '')),
              title: product.title,
              url: product.url,
              images: product.images,
              aiGenerated: false,
              discount: null,
              vendor: product.vendor,
            })
          })
        } else {
          prods
        }
      } catch (e) {
        window.shopify.toast.show(t('noProductUpdate'), {
          isError: true,
        })
      }
    }
    const set = new Set([...copy.map((p) => p.id), ...prods.map((p) => p.id)])
    const idMap = [...copy, ...prods].reduce((acc, p) => {
      acc[p.id] = p
      return acc
    }, {})
    const productsArray = Array.from(set)
      .map((id) => idMap[id])
      .sort((a, b) => {
        if (a.aiGenerated && !b.aiGenerated) return 1
        if (!a.aiGenerated && b.aiGenerated) return -1
        return 0
      })
    form.fields.products.onChange(productsArray)
  }

  return (
    <Layout.AnnotatedSection
      title={t('CheckoutUpsell.CheckoutUpsellConfig.Recommendation.title')}
      description={t(
        'CheckoutUpsell.CheckoutUpsellConfig.Recommendation.description',
      )}
    >
      <BlockStack gap={'300'}>
        <Card padding={'0'}>
          <Box padding={'400'}>
            <BlockStack gap={'200'}>
              <BlockStack gap={'100'}>
                <Text as="strong">
                  {t(
                    'CheckoutUpsell.CheckoutUpsellConfig.Recommendation.title',
                  )}
                </Text>
                <Text>
                  {t(
                    'CheckoutUpsell.CheckoutUpsellConfig.Recommendation.description',
                  )}
                </Text>
              </BlockStack>
            </BlockStack>
          </Box>
          <Divider />
          <RecommendationsProductSelector
            {...{
              selectedItems,
              setSelectedItems,
              form,
              productsLoading,
              productsOnChange: (item) => {
                const copy = [...form.fields.products.value]
                const index = copy.findIndex((p) => p.id === item.id)
                copy[index] = item
                form.fields.products.onChange(copy)
              },
              handlePopoverChange: recProductsHandlePopoverChange,
            }}
          />
        </Card>
        <ManualRecommendation
          openManualRecModal={() => {
            setFbtModalOpen(true)
          }}
          form={form}
        />
      </BlockStack>
      <ProductSelectorModal
        show={productSeqEditModalOpen}
        setShow={setProductSeqEditModalOpen}
        items={form.fields.products.value}
        onChange={(items) => {
          form.fields.products.onChange(items)
        }}
        onSave={() => {
          setProductSeqEditModalOpen(false)
        }}
        onDiscard={() => {
          setProductSeqEditModalOpen(false)
        }}
      />
      {/* <AddDiscountModal
        show={discountModalOpen}
        setShow={setDiscountModalOpen}
        refreshDiscounts={refreshDiscounts}
      /> */}
      <RecommendationSettingsModal
        setting={fbtModalOpen ? 'manualRecommendation' : null}
        dispatcher={(val) => setFbtModalOpen(Boolean(val))}
        widgetType={BOUGHT_TOGETHER.value}
        sectionId={null}
      />
    </Layout.AnnotatedSection>
  )
}

export default RecommedationsSection

function ManualRecommendation({ form, openManualRecModal }) {
  const { t } = useTranslation()
  return (
    <Card padding={'0'}>
      <Box padding={'400'}>
        <InlineGrid columns={'1fr auto'} gap={'300'}>
          <BlockStack gap={'100'}>
            <Text as="strong">
              {t(
                'CheckoutUpsell.CheckoutUpsellConfig.Recommendation.ManualRecommendation.title',
              )}
            </Text>
            <Text>
              {t(
                'CheckoutUpsell.CheckoutUpsellConfig.Recommendation.ManualRecommendation.description',
              )}
            </Text>
          </BlockStack>
          <div>
            <Button onClick={openManualRecModal}>
              {t(
                'CheckoutUpsell.CheckoutUpsellConfig.Recommendation.ManualRecommendation.cta',
              )}
            </Button>
          </div>
        </InlineGrid>
      </Box>
      <Divider />
      <Box padding={'400'}>
        <Checkbox
          checked={form.fields.overrideWithManualRec.checked}
          onChange={(val) => {
            form.fields.overrideWithManualRec.onChange(val)
          }}
          label={t(
            'CheckoutUpsell.CheckoutUpsellConfig.Recommendation.ManualRecommendation.overideText',
          )}
        />
      </Box>
    </Card>
  )
}
