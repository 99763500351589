import { useQuery } from 'react-query'
import { fetchAutomaticDiscount } from '../apis/shopify'

export function useSectionDiscount(dashboardData, enabled) {
  const query = useQuery({
    queryKey: ['sectionDiscount', dashboardData],
    queryFn: async () => {
      try {
        const data = await fetchAutomaticDiscount(dashboardData.config.sectionDiscountId)
        return data
      } catch (error) {
        console.error(error)
        return Promise.reject(error)
      }
    },
    enabled: Boolean(enabled && dashboardData?.config?.sectionDiscountId)
  })

  return {
    active: query.data?.status === 'ACTIVE',
    loading: query.isLoading,
  }
}
