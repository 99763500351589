import {
  IndexTable,
  IndexFilters,
  useIndexResourceState,
  Text,
  ChoiceList,
  Badge,
  BlockStack,
  InlineStack,
  Card,
  Tabs,
  Divider,
  Popover,
  Button,
  Scrollable,
  InlineGrid,
  Link,
  Tooltip,
  Avatar,
  Box,
  Spinner,
  Checkbox,
  Tag,
  useBreakpoints,
} from '@shopify/polaris'
import {
  ChevronDownIcon,
  ChevronUpIcon,
  DeleteIcon,
  UndoIcon,
} from '@shopify/polaris-icons'
import { useState, useCallback, useEffect } from 'react'
import { composeInitialProps, useTranslation } from 'react-i18next'

import ProductImage from '../ProductImage'
import {
  BOUGHT_TOGETHER,
  CROSS_SELL,
  SIMILAR_PRODUCTS,
} from '../../constants/widgets'
import { getQueryParams } from '../../utils/router'

export default function ProductRecommendationTable({
  products = [],
  widgetType,
  isLoading = false,
  productCorrespondingActions = {},
  showWidgetSpecific = true,
  pagination,
  searchQueryState = null,
  recFetching = false,
}) {
  const { t } = useTranslation()
  const [productCopy, setProductCopy] = useState(products)
  const [queryValue, setQueryValue] = searchQueryState || useState('')
  const params = getQueryParams()
  const { selectedResources } = useIndexResourceState(productCopy)
  const [showOnlyManuallyConfigured, setShowOnlyManuallyConfigured] =
    useState(false)

  const FILTERS = [
    {
      key: 'manualOnly',
      label: t('RecommendationPage.Components.showOnlyManual'),
      filter: (
        <Checkbox
          label={t('RecommendationPage.Components.showOnlyManual')}
          checked={showOnlyManuallyConfigured}
          onChange={(val) => {
            setShowOnlyManuallyConfigured(val)
          }}
        />
      ),
      shortcut: true,
      pinned: true,
    },
  ]

  const filterProductsByQuery = useCallback((query) => {
    if (!query) {
      setProductCopy(products)
      return
    }
    setProductCopy(
      productCopy.filter(({ product }) => {
        return (
          product.title.toLowerCase().includes(query.toLowerCase()) ||
          product.id.toLowerCase().includes(query.toLowerCase())
        )
      }),
    )
  }, [])

  const appliedFilters = []

  if (showOnlyManuallyConfigured) {
    appliedFilters.push({
      key: 'manualOnly',
      label: t('RecommendationPage.Components.showOnlyManual') + ': True',
      onRemove: () => {
        setShowOnlyManuallyConfigured(false)
      },
    })
  }

  useEffect(() => {
    filterProductsByQuery(queryValue)
  }, [queryValue])

  useEffect(() => {
    setProductCopy(products)
  }, [products])

  return (
    <Card padding={'0'}>
      <IndexFilters
        loading={isLoading}
        queryValue={queryValue}
        queryPlaceholder={t(
          'RecommendationPage.Components.ProductRecommendationTable.queryPlaceholder',
        )}
        canCreateNewViews={false}
        onQueryChange={setQueryValue}
        onQueryClear={() => setQueryValue('')}
        filters={FILTERS}
        appliedFilters={appliedFilters}
        onClearAll={() => {
          setShowOnlyManuallyConfigured(false)
        }}
        mode={'FILTERING'}
        tabs={[]}
      />
      <IndexTable
        loading={isLoading}
        pagination={pagination}
        resourceName={{
          singular: 'product',
          plural: 'products',
        }}
        itemCount={productCopy.length}
        headings={[
          {
            title: t(
              'RecommendationPage.Components.ProductRecommendationTable.heading1',
            ),
          },
          (showWidgetSpecific
            ? widgetType === SIMILAR_PRODUCTS.value
            : true) && {
            title: t(
              'RecommendationPage.Components.ProductRecommendationTable.heading2',
            ),
          },
          (showWidgetSpecific
            ? widgetType === BOUGHT_TOGETHER.value
            : true) && {
            title: t(
              'RecommendationPage.Components.ProductRecommendationTable.heading3',
            ),
          },
          (showWidgetSpecific ? widgetType === CROSS_SELL.value : true) && {
            title: t(
              'RecommendationPage.Components.ProductRecommendationTable.heading4',
            ),
          },
        ].filter((val) => Boolean(val))}
        selectable={false}
        emptyState={isLoading ? <Box /> : undefined}
      >
        {productCopy.map((item, index) => (
          <TableRow
            showWidgetSpecific={showWidgetSpecific}
            key={index}
            {...item}
            selected={selectedResources.includes(item.product.id)}
            position={index}
            url={`/settings/recommendation/${widgetType}/product/${item.product.id}${params.back ? `?back=${params.back}` : ''}`}
            {...(productCorrespondingActions[item.product.id] || {})}
            widgetType={widgetType}
            productsLoading={recFetching}
            showOnlyManuallyConfigured={showOnlyManuallyConfigured}
            onRecRemove={(recIds) => {
              console.log(recIds)
            }}
          />
        ))}
      </IndexTable>
    </Card>
  )
}

function TableRow({
  product,
  similarProducts,
  frequentlyBoughtTogether,
  crossSell,
  selected,
  position,
  url,
  onClick,
  showWidgetSpecific,
  widgetType,
  productsLoading,
  showOnlyManuallyConfigured,
  onRecRemove,
}) {
  const showSimilar = showWidgetSpecific
    ? widgetType === SIMILAR_PRODUCTS.value
    : true
  const showFbt = showWidgetSpecific
    ? widgetType === BOUGHT_TOGETHER.value
    : true
  const showCrossSell = showWidgetSpecific
    ? widgetType === CROSS_SELL.value
    : true
  const image =
    product?.images?.[0] ||
    '/assets/collection-placeholder.webp'
  const params = getQueryParams()
  const breakpoint = useBreakpoints()
  frequentlyBoughtTogether = frequentlyBoughtTogether.filter((product) =>
    showOnlyManuallyConfigured ? !product.aiGenerated : true,
  )
  crossSell = crossSell.filter((product) =>
    showOnlyManuallyConfigured ? !product.aiGenerated : true,
  )
  similarProducts = similarProducts.filter((product) =>
    showOnlyManuallyConfigured ? !product.aiGenerated : true,
  )

  return (
    <IndexTable.Row
      id={product.id}
      key={product.id}
      selected={selected}
      position={position}
    >
      <td
        style={{
          width: '40%',
          maxWidth: '40%',
        }}
        className="Polaris-IndexTable__TableCell"
      >
        <InlineGrid columns={'auto 1fr'} gap={'300'} alignItems="center">
          <ProductImage
            src={image}
            style={{
              width: 70,
              height: 100,
            }}
          />
          <StopPropagation>
            <BlockStack gap={'100'}>
              <InlineStack gap={'100'}>
                <Link
                  monochrome
                  url={onClick ? undefined : url}
                  onClick={onClick}
                >
                  <Text as="strong">{product.title}</Text>
                </Link>
                <Tag>ID: {product.id}</Tag>
              </InlineStack>
              <p
                style={{
                  width: '100%',
                  maxWidth: '100%',
                  whiteSpace: 'pre-wrap',
                  color: 'var(--p-color-text-secondary)',
                }}
              >
                {`Handle: ${product.handle} | Vendor: ${product.vendor} | Product Type: ${product.productType}`}
              </p>
              <Text tone="subdued">{product.price}</Text>
            </BlockStack>
          </StopPropagation>
        </InlineGrid>
      </td>
      {productsLoading && (
        <InlineStack gap={'300'} blockAlign="center">
          <Box padding={'400'}>
            <Spinner size="small" />
          </Box>
        </InlineStack>
      )}
      {!productsLoading && showSimilar && (
        <IndexTable.Cell>
          <InlineStack gap={'300'} blockAlign="center">
            {similarProducts?.length === 0 && (
              <Text>No Products Available</Text>
            )}
            {similarProducts
              .slice(0, showWidgetSpecific ? similarProducts.length : 3)
              .sort((a, b) => a.aiGenerated - b.aiGenerated)
              .sort((a, b) => {
                if (a.aiGenerated === b.aiGenerated) {
                  return b.score - a.score
                }
                return a.aiGenerated ? 1 : -1
              })
              .map((product) => (
                <StopPropagation key={product.id}>
                  <Tooltip
                    zIndexOverride={99999}
                    content={
                      <ProductCard
                        product={product}
                        onRemove={async () => {
                          await onRecRemove({
                            recommendedProducts: similarProducts.map(
                              (p) => p.id,
                            ),
                            recommendationType: SIMILAR_PRODUCTS.value,
                          })
                        }}
                      />
                    }
                  >
                    <Link
                      url={`/settings/recommendation/${widgetType}/product/${product.id}${params.back ? `?back=${params.back}` : ''}`}
                    >
                      <Avatar
                        source={
                          product.images[0] ||
                          '/assets/collection-placeholder.webp'
                        }
                        size="xl"
                      />
                    </Link>
                  </Tooltip>
                </StopPropagation>
              ))}
            {similarProducts.length > 3 && !showWidgetSpecific && (
              <div
                style={{
                  padding: 'var(--p-space-200)',
                  width: 40,
                  height: 50,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text>+{similarProducts.length - 3} more</Text>
              </div>
            )}
            {similarProducts.length > 0 && !showWidgetSpecific && (
              <ProductDropDown products={similarProducts} />
            )}
          </InlineStack>
        </IndexTable.Cell>
      )}
      {!productsLoading && showFbt && (
        <IndexTable.Cell>
          <InlineStack gap={'300'} blockAlign="center">
            {frequentlyBoughtTogether.length === 0 && (
              <Text>No Products Available</Text>
            )}
            {frequentlyBoughtTogether
              .slice(
                0,
                showWidgetSpecific ? frequentlyBoughtTogether.length : 3,
              )
              .sort((a, b) => a.aiGenerated - b.aiGenerated)
              .sort((a, b) => {
                if (a.aiGenerated === b.aiGenerated) {
                  return b.score - a.score
                }
                return a.aiGenerated ? 1 : -1
              })
              .map((product) => (
                <StopPropagation key={product.id}>
                  <Tooltip
                    zIndexOverride={99999}
                    content={
                      <ProductCard
                        product={product}
                        onRemove={async () => {
                          await onRecRemove({
                            recommendedProducts: frequentlyBoughtTogether.map(
                              (p) => p.id,
                            ),
                            recommendationType: BOUGHT_TOGETHER.value,
                          })
                        }}
                      />
                    }
                  >
                    <Link
                      url={`/settings/recommendation/${widgetType}/product/${product.id}${params.back ? `?back=${params.back}` : ''}`}
                    >
                      <Avatar
                        source={
                          product.images[0] ||
                          '/assets/collection-placeholder.webp'
                        }
                        size="xl"
                      />
                    </Link>
                  </Tooltip>
                </StopPropagation>
              ))}
            {frequentlyBoughtTogether.length > 3 && !showWidgetSpecific && (
              <div
                style={{
                  padding: 'var(--p-space-200)',
                  width: 40,
                  height: 50,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text>+{frequentlyBoughtTogether.length - 3} more</Text>
              </div>
            )}
            {frequentlyBoughtTogether.length > 0 && !showWidgetSpecific && (
              <ProductDropDown products={frequentlyBoughtTogether} />
            )}
          </InlineStack>
        </IndexTable.Cell>
      )}
      {!productsLoading && showCrossSell && (
        <IndexTable.Cell>
          <InlineStack gap={'300'} blockAlign="center">
            {crossSell.length === 0 && <Text>No Products Available</Text>}
            {crossSell
              .slice(0, showWidgetSpecific ? crossSell.length : 3)
              .sort((a, b) => a.aiGenerated - b.aiGenerated)
              .sort((a, b) => {
                if (a.aiGenerated === b.aiGenerated) {
                  return b.score - a.score
                }
                return a.aiGenerated ? 1 : -1
              })
              .map((product) => (
                <StopPropagation key={product.id}>
                  <Tooltip
                    zIndexOverride={99999}
                    content={
                      <ProductCard
                        product={product}
                        onRemove={async () => {
                          await onRecRemove({
                            recommendedProducts: crossSell.map((p) => p.id),
                            recommendationType: CROSS_SELL.value,
                          })
                        }}
                      />
                    }
                    key={product.id}
                  >
                    <Link
                      url={`/settings/recommendation/${widgetType}/product/${product.id}${params.back ? `?back=${params.back}` : ''}`}
                    >
                      <Avatar
                        source={
                          product.images[0] ||
                          '/assets/collection-placeholder.webp'
                        }
                        size="xl"
                      />
                    </Link>
                  </Tooltip>
                </StopPropagation>
              ))}
            {crossSell.length > 3 && !showWidgetSpecific && (
              <div
                style={{
                  padding: 'var(--p-space-200)',
                  width: 40,
                  height: 50,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text>+{crossSell.length - 3} more</Text>
              </div>
            )}
            {crossSell.length > 0 && !showWidgetSpecific && (
              <ProductDropDown products={crossSell} />
            )}
          </InlineStack>
        </IndexTable.Cell>
      )}
    </IndexTable.Row>
  )
}

function ProductDropDown({ products }) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <Popover
      activator={
        <StopPropagation>
          <Button
            variant="plain"
            onClick={() => {
              setOpen(!open)
            }}
            icon={open ? ChevronUpIcon : ChevronDownIcon}
          />
        </StopPropagation>
      }
      active={open}
      preferredAlignment="right"
      onClose={() => setOpen(false)}
    >
      <div
        style={{
          zIndex: 99999,
        }}
      >
        <Popover.Pane fixed>
          <Scrollable
            shadow
            style={{
              position: 'relative',
              width: '400px',
              maxHeight: '500px',
              padding: 'var(--p-space-200) 0',
              borderBottomLeftRadius: 'var(--p-border-radius-200)',
              borderBottomRightRadius: 'var(--p-border-radius-200)',
              zIndex: 99999999,
            }}
          >
            {products.map((product, idx) => (
              <>
                <div
                  key={product.id}
                  style={{
                    padding: 'var(--p-space-200)',
                  }}
                >
                  <InlineGrid columns={'auto 1fr'} gap={'400'}>
                    <img src={product.images?.[0]} width={60} height={75} />
                    <BlockStack gap={'200'}>
                      <Text as="strong">{product.title}</Text>
                      <InlineGrid columns={'auto 1fr'} gap={'300'}>
                        <div>
                          <Text>
                            {`ID: ${product.id} | Handle: ${product.handle}`}
                          </Text>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'end',
                          }}
                        >
                          {product.aiGenerated && (
                            <Badge tone="info-strong">
                              <span
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {t(
                                  'RecommendationPage.Components.ProductRecommendationTable.aiGeneratedText',
                                )}
                              </span>
                            </Badge>
                          )}
                        </div>
                      </InlineGrid>
                    </BlockStack>
                  </InlineGrid>
                </div>
                {idx !== products.length - 1 && <Divider />}
              </>
            ))}
          </Scrollable>
        </Popover.Pane>
      </div>
    </Popover>
  )
}

function ProductCard({ product }) {
  const { t } = useTranslation()
  return (
    <div
      style={{
        padding: 'var(--p-space-200)',
        position: 'relative',
      }}
    >
      <BlockStack align="center">
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ProductImage
            src={product.images?.[0] || '/assets/collection-placeholder.webp'}
            style={{
              width: 70,
              height: 100,
            }}
          />
        </div>
        <BlockStack gap={'100'}>
          <InlineStack gap={'100'}>
            <Text as="strong">
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'block',
                }}
              >
                {product.title
                  .slice(0, 20)
                  .concat(product.title.length > 20 ? '...' : '')}
              </span>
            </Text>
            <Tag>ID: {product.id}</Tag>
          </InlineStack>
          <Text tone="subdued">
            {`Handle: ${product.handle} | Vendor: ${product.vendor} | Product Type: ${product.productType}`}
          </Text>
          <Text tone="subdued" variant="bodyMd" as="strong">
            <span
              dangerouslySetInnerHTML={{
                __html: product.price,
              }}
            />
          </Text>
        </BlockStack>
      </BlockStack>
      {product.aiGenerated && (
        <div
          style={{
            position: 'absolute',
            top: 'var(--p-space-100)',
            right: 'var(--p-space-100)',
          }}
        >
          <Badge tone="info-strong">{t('aiGeneratedProduct')}</Badge>
        </div>
      )}
    </div>
  )
}

const StopPropagation = ({ children }) => {
  const stopEventPropagation = (event) => {
    event.stopPropagation()
  }

  return (
    <div
      onClick={stopEventPropagation}
      onMouseEnter={stopEventPropagation}
      onMouseLeave={stopEventPropagation}
      onTouchStart={stopEventPropagation}
    >
      {children}
    </div>
  )
}
