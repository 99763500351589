import React, { useState } from 'react'
import {
  BlockStack,
  Button,
  Card,
  Divider,
  InlineGrid,
  InlineStack,
  Layout,
  Tag,
  Text,
  ResourceList,
  ResourceItem,
  Avatar,
  FormLayout,
  SkeletonDisplayText,
  Banner,
  Link,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { EditIcon } from '@shopify/polaris-icons'

import { DISCOUNT_TYPES } from '../../../../constants/discounts'
import { AMAZON_BOUGHT_TOGETHER } from '../../../../constants/layouts'
import { PRODUCT } from '../../../../constants/page'
import { areFeaturesEnabled } from '../../../../utils/features'
import { FEATURES } from '../../../../constants/features'
import { currencyFormatter } from '../../../../utils/formater'
import { useSectionDiscount } from '../../../../hooks/useSectionDiscount'
import { openChat } from '../../../../components/ExternalScripts'

function DiscountConfig({ section, onClick, page, dashboardData }) {
  const { t } = useTranslation()
  const discountType = section.discountConfig?.type,
    discountValue = section.discountConfig?.value,
    discountMessage = section.discountConfig?.widgetMessage
      ? typeof section.discountConfig?.widgetMessage === 'string'
        ? section.discountConfig?.widgetMessage
        : section.discountConfig?.widgetMessage?.primaryLocale?.title ||
          section.discountConfig?.widgetMessage?.primaryLocale
      : null,
    cartMessage =
      section.discountConfig?.version != 2
        ? section.discountConfig?.cartMessage
        : section.discountConfig?.message?.primaryLocale?.title ||
          section.discountConfig?.widgetMessage?.primaryLocale,
    enabled = section.discountConfig?.enabled

  const featureEnabled = areFeaturesEnabled(
    [FEATURES.DISCOUNTS],
    window.shopify.data,
  )

  const automaticDiscount = useSectionDiscount(dashboardData, featureEnabled)

  const discountBanner = automaticDiscount.loading ? (
    <SkeletonDisplayText size="medium" maxWidth="100%" />
  ) : (
    !automaticDiscount.active &&
    section.discountConfig?.version == 2 && (
      <Banner
        tone="warning"
        title={t('Section.Edit.Sections.DiscountConfig.banner.title', {
          cta: (
            <Link onClick={openChat}>{t('DefaultText.contactSupport')}</Link>
          ),
        })}
        status="warning"
      />
    )
  )

  if (!featureEnabled) {
    return null
  }

  if (
    section.layout !== AMAZON_BOUGHT_TOGETHER.value ||
    page !== PRODUCT.value
  ) {
    return (
      <Layout.AnnotatedSection
        title={t('Section.Details.BundleConfig.title')}
        description={t('Section.Details.BundleConfig.description')}
      >
        <Card>
          <BlockStack gap={'300'}>
            {discountBanner}
            <InlineGrid columns={'1fr auto'}>
              <BlockStack gap={'400'}>
                {enabled && (
                  <FormLayout>
                    <FormLayout.Group>
                      <Text>
                        <strong>
                          {t(
                            'Section.Edit.Sections.DiscountConfig.form.applyDiscountOnlyToRec.label',
                          )}
                        </strong>{' '}
                        :{' '}
                        {section.discountConfig?.applyDiscountOnlyToRec
                          ? t('DefaultText.enabled')
                          : t('DefaultText.disabled')}
                      </Text>
                    </FormLayout.Group>
                  </FormLayout>
                )}
                {enabled ? (
                  <>
                    <InlineGrid columns={2} gap={'400'}>
                      <div>
                        <Text as="h4" variant="bodyMd">
                          {t(
                            'Section.Details.BundleConfig.FormFields.DiscountType.label',
                          )}
                        </Text>
                        <Text variant="headingMd" as="strong">
                          {
                            Object.values(DISCOUNT_TYPES).find(
                              ({ value }) => value === discountType,
                            )?.label
                          }
                        </Text>
                      </div>
                      <div>
                        <Text as="h4" variant="bodyMd">
                          {t(
                            'Section.Details.BundleConfig.FormFields.DiscountValue.label',
                          )}
                        </Text>
                        <Text variant="headingMd" as="strong">
                          {discountValue}
                        </Text>
                      </div>
                      <div>
                        <Text as="h4" variant="bodyMd">
                          {t(
                            'Section.Details.BundleConfig.FormFields.DiscountMessage.label',
                          )}
                        </Text>
                        <Text variant="headingMd" as="strong">
                          {discountMessage}
                        </Text>
                      </div>
                    </InlineGrid>
                  </>
                ) : (
                  <Text>{t('Section.Details.BundleConfig.disabled')}</Text>
                )}
              </BlockStack>
              <div>
                <Button icon={EditIcon} onClick={onClick}>
                  {t('Section.Details.BundleConfig.btnText')}
                </Button>
              </div>
            </InlineGrid>
          </BlockStack>
        </Card>
      </Layout.AnnotatedSection>
    )
  }

  return (
    <Layout.AnnotatedSection
      title={t('Section.Details.BundleConfig.title')}
      description={t('Section.Details.BundleConfig.description')}
    >
      <Card>
        <BlockStack gap={'300'}>
          {discountBanner}
          <InlineGrid columns={'1fr auto'}>
            <BlockStack gap={'400'}>
              {enabled ? (
                <>
                  <InlineGrid columns={2} gap={'400'}>
                    <div>
                      <Text as="h4" variant="bodyMd">
                        {t(
                          'Section.Edit.Sections.DiscountConfig.form.applyDiscountOnlyToRec.label',
                        )}
                      </Text>
                      <Text variant="headingMd" as="strong">
                        {section.discountConfig?.applyDiscountOnlyToRec
                          ? t('DefaultText.enabled')
                          : t('DefaultText.disabled')}
                      </Text>
                    </div>
                    <div>
                      <Text as="h4" variant="bodyMd">
                        {t(
                          'Section.Details.BundleConfig.FormFields.DiscountType.label',
                        )}
                      </Text>
                      <Text variant="headingMd" as="strong">
                        {
                          Object.values(DISCOUNT_TYPES).find(
                            ({ value }) => value === discountType,
                          )?.label
                        }
                      </Text>
                    </div>
                    <div>
                      <Text as="h4" variant="bodyMd">
                        {t(
                          'Section.Details.BundleConfig.FormFields.DiscountValue.label',
                        )}
                      </Text>
                      <Text variant="headingMd" as="strong">
                        {discountType === DISCOUNT_TYPES.PERCENTAGE.value
                          ? `${discountValue}%`
                          : currencyFormatter(
                              window.shopify.data.shop.currency,
                              discountValue,
                            )}
                      </Text>
                    </div>
                    <div>
                      <Text as="h4" variant="bodyMd">
                        {t(
                          'Section.Details.BundleConfig.FormFields.DiscountMessage.label',
                        )}
                      </Text>
                      <Text variant="headingMd" as="strong">
                        {discountMessage || <strong>None</strong>}
                      </Text>
                    </div>
                    <div>
                      <Text as="h4" variant="bodyMd">
                        {t(
                          'Section.Details.BundleConfig.FormFields.MsgOnCartPage.label',
                        )}
                      </Text>
                      <Text variant="headingMd" as="strong">
                        {cartMessage || <strong>None</strong>}
                      </Text>
                    </div>
                  </InlineGrid>
                </>
              ) : (
                <Text>{t('Section.Details.BundleConfig.disabled')}</Text>
              )}
            </BlockStack>
            <div>
              <Button icon={EditIcon} onClick={onClick}>
                {t('Section.Details.BundleConfig.btnText')}
              </Button>
            </div>
          </InlineGrid>
        </BlockStack>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export default DiscountConfig
