import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
  ActionList,
  Banner,
  BlockStack,
  Box,
  Card,
  Divider,
  EmptyState,
  Icon,
  InlineGrid,
  Popover,
  SkeletonBodyText,
  SkeletonDisplayText,
  Tabs,
  Text,
  TextField,
  useBreakpoints,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import ProductSequenceEdit from '../../../../components/ProductSequenceEdit'
import { WIDGET_TYPES } from '../../../../constants/widgets'
import { canAccessResourcePicker } from '../../../../utils/access'
import Button from '../../../../components/Button'

function ConfigureProductRecommendation({
  product,
  setSelectedProducts,
  selectedProducts,
  selectedTab,
  hideOtherWidgets = false,
  onProductTitleClick,
  // productAddingNumber is used to show skeleton when product is getting added
  productAddingNumber,
  onReverseProductChange,
  dataFetching,
  productRemoving
}) {
  const { t } = useTranslation()
  const breakpoints = useBreakpoints()
  const TABS = [
    {
      content: t('RecommendingProductType.bought_together.title'),
      id: WIDGET_TYPES.bought_together.value,
    },
    {
      content: t('RecommendingProductType.similar_products.title'),
      id: WIDGET_TYPES.similar_products.value,
    },
    {
      content: t('RecommendingProductType.cross_sell.title'),
      id: WIDGET_TYPES.cross_sell.value,
    },
  ]

  const productPicker = async (reverseProducts = false) => {
    const selectedProductIds = selectedProducts
      .filter((product) => {
        return !product.aiGenerated
      })
      .map((product) => ({
        id: product.id,
      }))
    try {
      const selected = await shopify.resourcePicker({
        type: 'product',
        selectionIds: selectedProductIds,
        multiple: 20,
        filter: {
          variants: false,
          query: `-${product.id.replace('gid://shopify/Product/', '')}`,
          archived: false,
        },
      })
      if (!selected) {
        return
      } else {
        const processed = new Array(...selected).map((product) => ({
          aiGenerated: false,
          handle: product.handle,
          id: product.id,
          images: product.images.map(({ originalSrc }) => originalSrc),
          title: product.title,
          vendor: product.vendor,
          tags: product.tags,
          productType: product.productType,
          variants: product.variants,
        }))
        if (reverseProducts) {
          onReverseProductChange(processed)
        } else {
          setSelectedProducts(processed)
        }
      }
    } catch (e) {
      window.shopify.toast.show(t('noProductUpdate'), {
        isError: true,
      })
    }
  }

  const aiGeneratedProducts = selectedProducts.filter(
    (product) => product.aiGenerated,
  )
  const manuallyConfiguredProducts = selectedProducts.filter(
    (product) => !product.aiGenerated,
  )

  return (
    <>
      <Card padding={'0'}>
        <Divider />
        <div
          style={{
            padding: 'var(--p-space-400)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 'var(--p-space-400)',
              width: '100%',
            }}
          >
            <div>
              <Text as="strong">
                {t(`RecommendingProductType.${TABS[selectedTab]?.id}.title`)}
              </Text>
              <InlineGrid columns={'1fr auto'} gap={'200'}>
                <Text>
                  {t(
                    `RecommendingProductType.${TABS[selectedTab]?.id}.description`,
                  )}
                </Text>
                <ProductSelectorPopover
                  onProductPick={() => {
                    productPicker(false)
                  }}
                  onReverseProductPick={() => {
                    productPicker(true)
                  }}
                />
              </InlineGrid>
            </div>
          </div>
        </div>
        <Divider />
        <InlineGrid
          columns={{
            xs: 1,
            sm: 1,
            md: 2,
            lg: 2,
            xl: 2,
          }}
        >
          <div
            style={{
              borderRight:
                breakpoints.xlUp && '1px solid var(--p-color-border)',
            }}
          >
            <BlockStack>
              <Box padding={'400'}>
                <Text variant="headingSm">
                  {t('manuallyConfiguredRecTitle')}
                </Text>
                <Text tone="subdued">
                  {t('manuallyConfiguredRecDescription')}
                </Text>
              </Box>
              {dataFetching || productRemoving ? (
                <BlockStack>
                  {Array.from({ length: 2 }).map((_, index) => (
                    <Fragment key={index}>
                      <Divider />
                      <Box padding={'400'}>
                        <BlockStack gap={'200'}>
                          <SkeletonDisplayText size="small" />
                          <SkeletonBodyText lines={1} />
                        </BlockStack>
                      </Box>
                    </Fragment>
                  ))}
                </BlockStack>
              ) : (
                <ProductSequenceEdit
                  loading={Boolean(productAddingNumber)}
                  items={manuallyConfiguredProducts}
                  onChange={(array) => {
                    setSelectedProducts([...array, ...aiGeneratedProducts])
                  }}
                  onTitleClick={onProductTitleClick}
                  addDividerAtEnd={
                    manuallyConfiguredProducts.length <
                    aiGeneratedProducts.length
                  }
                  insertingSkeletons={
                    productAddingNumber
                  }
                />
              )}
            </BlockStack>
          </div>
          <BlockStack>
            <Box padding={'400'}>
              <Text variant="headingSm">{t('aiGeneratedRecTitle')}</Text>
              <Text tone="subdued">{t('aiGeneratedRecDescription')}</Text>
            </Box>
            {dataFetching ? (
              <BlockStack>
                {Array.from({ length: 2 }).map((_, index) => (
                  <Fragment key={index}>
                    <Divider />
                    <Box padding={'400'}>
                      <BlockStack gap={'200'}>
                        <SkeletonDisplayText size="small" />
                        <SkeletonBodyText lines={1} />
                      </BlockStack>
                    </Box>
                  </Fragment>
                ))}
              </BlockStack>
            ) : (
              <ProductSequenceEdit
                loading={Boolean(productAddingNumber)}
                items={aiGeneratedProducts}
                onChange={(array) => {
                  setSelectedProducts([...manuallyConfiguredProducts, ...array])
                }}
                onTitleClick={onProductTitleClick}
                emptyText={t('emptyAiGeneratedRec')}
                addDividerAtEnd={
                  aiGeneratedProducts.length < manuallyConfiguredProducts.length
                }
                insertingSkeletons={
                  0
                }
              />
            )}
          </BlockStack>
        </InlineGrid>
      </Card>
    </>
  )
}

export default ConfigureProductRecommendation

function ProductSelectorPopover({ onProductPick, onReverseProductPick }) {
  const [active, setActive] = useState(false)
  const { t } = useTranslation()
  const canPickProducts = canAccessResourcePicker(window.shopify.data)
  return (
    <Popover
    onClose={() => setActive(false)}
      active={active}
      activator={
        <Button
          disabled={!canPickProducts}
          tooltipText={!canPickProducts && t('noProductPermission')}
          onClick={() => setActive(!active)}
          disclosure
        >
          {t('RecommendationPage.NewProductRecommendation.addProductTitle')}
        </Button>
      }
    >
      <Popover.Pane>
        <ActionList
          items={[
            {
              content: t('BundlesUpsert.ProductsSelector.selector.addProduct'),
              onAction: onProductPick,
            },
            {
              content: t(
                'BundlesUpsert.ProductsSelector.selector.reverseProduct',
              ),
              onAction: onReverseProductPick,
            },
          ]}
        />
      </Popover.Pane>
    </Popover>
  )
}
