import { PAGE_TYPES } from '../constants/page'
import { WIDGET_TYPES } from '../constants/widgets'

export function shouldDiscountConfigRender({ type, pageType }) {
  const allowedPages = [
      PAGE_TYPES.ajax_cart.value,
      PAGE_TYPES.product_page.value,
      PAGE_TYPES.cart.value,
    ],
    allowedSections = [WIDGET_TYPES.bought_together.value]

  return allowedPages.includes(pageType) && allowedSections.includes(type)
}
